import { OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginService } from "../../../services/aws/user-login.service";
import { CognitoUtil } from "../../../services/aws/cognito.service";
//import { FlowService } from "../../../services/flow.service";
import { CognitoUserSession } from "amazon-cognito-identity-js";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, userService, cognitoUtil) {
        this.router = router;
        this.userService = userService;
        this.cognitoUtil = cognitoUtil;
        console.log("LoginComponent constructor");
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.errorMessage = null;
        console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
        this.userService.isAuthenticated(function (isValidSession) {
            if (isValidSession) {
                _this.router.navigate(['/pg/home']);
            }
        });
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptionAddUserToGroup && !this.subscriptionAddUserToGroup.closed) {
            this.subscriptionAddUserToGroup.unsubscribe();
        }
    };
    LoginComponent.prototype.onLogin = function () {
        var _this = this;
        if (this.email == null || this.password == null) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.errorMessage = null;
        this.userService.authenticate(this.email, this.password, function (result, error) {
            if (error) {
                _this.errorMessage = error;
                // Error while authentication
                console.log("result: " + _this.errorMessage);
                if (_this.errorMessage === 'User is not confirmed.') {
                    console.log("redirecting");
                    _this.router.navigate(['/pg/confirmRegistration', _this.email]);
                }
                else if (_this.errorMessage === 'User needs to set password.') {
                    console.log("redirecting to set new password");
                    _this.router.navigate(['/pg/newPassword']);
                }
                return;
            }
            if (result) {
                if (result instanceof CognitoUserSession) {
                    //this.addUserToGroup(result.getAccessToken().getJwtToken());
                }
                _this.router.navigate(['/pg/home']);
            }
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
