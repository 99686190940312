import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginService } from "../../../services/aws/user-login.service";
import { CognitoUtil } from "../../../services/aws/cognito.service";
//import { FlowService } from "../../../services/flow.service";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Subscription } from "rxjs";

@Component({
    selector: 'awscognito-angular2-app',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
    email: string;
    password: string;
    errorMessage: string;

    private subscriptionAddUserToGroup: Subscription;

    constructor(
        public router: Router,
        public userService: UserLoginService,
        private cognitoUtil: CognitoUtil
    ) {
        console.log("LoginComponent constructor");
    }

    ngOnInit() {
        this.errorMessage = null;
        console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
        this.userService.isAuthenticated((isValidSession) => {
            if (isValidSession) {
                this.router.navigate(['/pg/home']);
            }
        });
    }

    ngOnDestroy() {
        if (this.subscriptionAddUserToGroup && !this.subscriptionAddUserToGroup.closed) {
            this.subscriptionAddUserToGroup.unsubscribe();
        }
    }

    onLogin() {
        if (this.email == null || this.password == null) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.errorMessage = null;
        this.userService.authenticate(this.email, this.password, (result, error) => {
            if (error) {
                this.errorMessage = error;

                // Error while authentication
                console.log("result: " + this.errorMessage);
                if (this.errorMessage === 'User is not confirmed.') {
                    console.log("redirecting");
                    this.router.navigate(['/pg/confirmRegistration', this.email]);
                } else if (this.errorMessage === 'User needs to set password.') {
                    console.log("redirecting to set new password");
                    this.router.navigate(['/pg/newPassword']);
                }

                return;
            }
            if (result) {
                if (result instanceof CognitoUserSession) {
                    //this.addUserToGroup(result.getAccessToken().getJwtToken());
                }
                this.router.navigate(['/pg/home']);
            }
        });
    }

    // cognitoCallback(message: string, result: any) {
    //     if (message != null) { //error
    //         this.errorMessage = message;
    //         console.log("result: " + this.errorMessage);
    //         if (this.errorMessage === 'User is not confirmed.') {
    //             console.log("redirecting");
    //             this.router.navigate(['/pg/confirmRegistration', this.email]);
    //         } else if (this.errorMessage === 'User needs to set password.') {
    //             console.log("redirecting to set new password");
    //             this.router.navigate(['/pg/newPassword']);
    //         }
    //     } else { //success
    //         console.log('LogIn Response :: ', result);
    //         if (result instanceof CognitoUserSession) {
    //             this.addUserToGroup(result.getAccessToken().getJwtToken());
    //         }
    //         this.router.navigate(['/pg/home']);
    //     }
    // }

    // isLoggedIn(message: string, isLoggedIn: boolean) {
    //     if (isLoggedIn)
    //         this.router.navigate(['/pg/home']);
    // }

    // addUserToGroup(access_token: string) {
    //     const payload = {
    //         'access_token': access_token,
    //         'username': this.cognitoUtil.getCurrentUser().getUsername(),
    //         'group': 'consumer'
    //     };

    //     this.subscriptionAddUserToGroup = this.flowService.initiateFlow(payload, 'GMKPostSignIn')
    //         .subscribe(
    //             response => {
    //                 console.log('GMK Post Sign In :: response', response);
    //             },
    //             error => {
    //                 console.log('GMK Post Sign In :: error', error);
    //             }
    //         );
    // }
}