import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
    // AuthenticationDetails,
    // CognitoIdentityServiceProvider,
    CognitoUser,
    // CognitoUserAttribute,
    CognitoUserPool
} from "amazon-cognito-identity-js";
// import * as AWS from "aws-sdk/global";
// import * as awsservice from "aws-sdk/lib/service";
// import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";


/**
 * Created by Swami Naik
 */

@Injectable()
export class CognitoUtil {

    public static _REGION = environment.region;

    public static _USER_POOL_ID = environment.userPoolId;
    public static _CLIENT_ID = environment.clientId;

    public static _POOL_DATA: any = {
        UserPoolId: CognitoUtil._USER_POOL_ID,
        ClientId: CognitoUtil._CLIENT_ID
    };

    getUserPool() {
        if (environment.cognito_idp_endpoint) {
            CognitoUtil._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
        }
        return new CognitoUserPool(CognitoUtil._POOL_DATA);
    }

    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }

    getAccessToken(callbackFunction: Function): void {
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.error("CognitoUtil: Can't set the credentials:" + err);
                    callbackFunction(null);
                } else {
                    if (session.isValid()) {
                        callbackFunction(session.getAccessToken().getJwtToken());
                    } else {
                        console.error("CognitoUtil: Got the access token, but the session isn't valid");
                        callbackFunction(null);
                    }
                }
            });
        } else {
            console.error("CognitoUtil: Current user not found");
            callbackFunction(null);
        }
    }

    getIdToken(callbackFunction: Function): void {
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.error("CognitoUtil: Can't set the credentials:" + err);
                    callbackFunction(null);
                } else {
                    if (session.isValid()) {
                        callbackFunction(session.getIdToken().getJwtToken());
                    } else {
                        console.error("CognitoUtil: Got the id token, but the session isn't valid");
                        callbackFunction(null);
                    }
                }
            });
        } else {
            console.error("CognitoUtil: Current user not found");
            callbackFunction(null);
        }
    }

    getRefreshToken(callbackFunction: Function): void {
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.error("CognitoUtil: Can't set the credentials:" + err);
                    callbackFunction(null);
                } else {
                    if (session.isValid()) {
                        callbackFunction(session.getRefreshToken());
                    } else {
                        console.error("CognitoUtil: Got the refresh token, but the session isn't valid");
                        callbackFunction(null);
                    }
                }
            });
        } else {
            console.error("CognitoUtil: Current user not found");
            callbackFunction(null);
        }
    }

    getSession(callbackFunction: Function): void {
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.error("CognitoUtil: Can't set the credentials:" + err);
                    callbackFunction(null);
                } else {
                    if (session.isValid()) {
                        callbackFunction(session);
                    } else {
                        console.error("CognitoUtil: Got the session, but the session isn't valid");
                        callbackFunction(null);
                    }
                }
            });
        } else {
            console.error("CognitoUtil: Current user not found");
            callbackFunction(null);
        }
    }

    refresh(): void {
        this.getCurrentUser().getSession(function (err, session) {
            if (err) {
                console.log("CognitoUtil: Can't set the credentials:" + err);
            }

            else {
                if (session.isValid()) {
                    console.log("CognitoUtil: refreshed successfully");
                } else {
                    console.log("CognitoUtil: refreshed but session is still not valid");
                }
            }
        });
    }
}