import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
var SaveServices = /** @class */ (function () {
    function SaveServices(httpClient) {
        this.httpClient = httpClient;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
        });
    }
    SaveServices.prototype.saveComment = function (description, related_to, callType, assignedTo, status, priority, type, createdDt) {
        var subject = new Subject();
        var url = '';
        if (type == "Claim") {
            url = "" + (environment.apiurl + environment.saveComment);
        }
        else {
            url = "" + (environment.apiurl + environment.savePolicyComment);
        }
        //const url = `${environment.apiurl + environment.saveComment}`;
        //const body = {"imei-number":searchText,"companyContext":{"companyId":environment.companyId}};
        //{"parentId":"15x11964848","callType":"TODO","description":"POLICY DESCRIPTION 12344","assignedTo":"SHIVAM","status":"ASSIGNED STATUS","priority":"MEDIUM","createdDt":"2017-02-17"}
        var body = { "description": description, "parentId": related_to, "callType": callType, "assignedTo": assignedTo, "status": status, "priority": priority, "createdDt": createdDt, "type": type };
        //console.log({"description":description,"parentId":related_to,"callType":callType,"assignedTo":assignedTo,"status":status,"priority":priority});
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                subject.next(response.body);
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    SaveServices.prototype.saveCommentFromUser = function (comment) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.comments);
        var body = {
            key: null,
            value: comment,
            operation: "SAVE",
            companyContext: { companyId: environment.companyId }
        };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                console.log(response.body);
                if (response.body.success) {
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log("error");
            console.log(err);
        });
        return subject.asObservable();
    };
    return SaveServices;
}());
export { SaveServices };
