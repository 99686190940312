import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { SuccessResponse } from '../models/success.model'
import { Comments } from '../models/comments.model';

@Injectable()
export class SaveServices {

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json','Access-Control-Allow-Origin':'*'
    });

    constructor(
        private httpClient: HttpClient
    ) { }

    saveComment(description: string,related_to:string,callType:string,assignedTo:string,status:string,priority:string,type:string,createdDt:string): Observable<SuccessResponse> {
        const subject = new Subject<SuccessResponse>();
        var url ='';
        if (type=="Claim")
        {
            url = `${environment.apiurl + environment.saveComment}`;
        }
        else{
             url = `${environment.apiurl + environment.savePolicyComment}`;
        }
        //const url = `${environment.apiurl + environment.saveComment}`;
        //const body = {"imei-number":searchText,"companyContext":{"companyId":environment.companyId}};
        //{"parentId":"15x11964848","callType":"TODO","description":"POLICY DESCRIPTION 12344","assignedTo":"SHIVAM","status":"ASSIGNED STATUS","priority":"MEDIUM","createdDt":"2017-02-17"}
        const body ={"description":description,"parentId":related_to,"callType":callType,"assignedTo":assignedTo,"status":status,"priority":priority,"createdDt":createdDt,"type":type};
        //console.log({"description":description,"parentId":related_to,"callType":callType,"assignedTo":assignedTo,"status":status,"priority":priority});
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<SuccessResponse>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<SuccessResponse>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    saveCommentFromUser(comment: Comments): Observable<Comments> {
        const subject = new Subject<Comments>();
        const url = `${environment.apiurl + environment.comments}`;
        const body = {
            key: null,
            value: comment,
            operation: "SAVE",
            companyContext: { companyId: environment.companyId } 

        };
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<Comments>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    console.log(response.body);
                    if (response.body.success) {
                        subject.next(response.body.result);
                    }
                    
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }
}