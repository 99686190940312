<div *ngIf="loadPage">
<!-- begin:: Page -->
<div class="m-grid m-grid--hor m-grid--root m-page">
      <!-- BEGIN: Header -->
      <header id="m_header" class="m-grid__item    m-header ">
         <div class="m-container m-container--fluid m-container--full-height">
            <div class="m-stack m-stack--ver m-stack--desktop">
               <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                  <div class="row">
                     <div class="col-md-3">
                        <!-- BEGIN: Horizontal Menu -->
                        <div id="m_header_menu" class="m-header-menu">
                           <ul class="m-menu__nav">
                              <li class="m-menu__item"><img src="assets/images/AMS-Logo.png" class="ams-logo"></li>
                           </ul>
                        </div>
                        <!-- END: Horizontal Menu -->
                     </div>
                     <div class="col-md-8">
                        <div id="m_header_menu" class="m-header-menu pull-right-hard">
                           <ul class="m-menu__nav">
                              <li class="m-menu__item">Client Name: <br><span>{{ selectedClientName }}</span></li>
                              <li class="m-menu__item">Customer Name: <br><span>{{ getCustomerName(searchResult.customerInfo) }}</span>
                              </li>
                              <li class="m-menu__item">Policy No: <br><span>{{ selectedPolicyNo }}</span></li>
                              <li class="m-menu__item">Mobile No: <br><span>{{ getcustomerMobileNumber() }}</span>
                              </li>
                              <li class="m-menu__item">IMEI No:<br>
                                 <span
                                    *ngIf="searchResult != null && searchResult.policies != null && searchResult.policies.length > 0">
                                    <span>{{ searchResult.customerInfo.imei != null && searchResult.customerInfo.imei.trim().length != 0 ? searchResult.customerInfo.imei : getImeiNumber() }}</span>
                                 </span>
                              </li>
                              <!--<li class="m-menu__item">
                                 <span class="m-list-search__form-input-wrapper">
                                     <input id="m_quicksearch_input" style="color: gray;" type="text" name="q"
                                       class="m-list-search__form-input" value="" placeholder="Find IMEI No."
                                       [(ngModel)]="textToBeSearch" (keydown.enter)="searchText()">
                                 </span>
                              </li> -->
                              <!-- <li class="m-menu__item">
                                 <a href="#" class="m-nav__link" (click)="searchText()">
                                    <span id="m_quicksearch" class="m-nav__link-icon"><i class="fas fa-search"></i></span>
                                 </a>
                              </li> -->
   
                              <li class="m-menu__item" style="width: 80px"><span class="fixed-time-column-width"
                                    style="font-weight: bold;font-size: 18px">
                                    {{minuteadd}}{{minutes}}:{{secondadd}}{{timeLeft}}</span>
                              </li>
                              <li class="m-menu__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                                 m-dropdown-toggle="click">
   
                                 <a href="#" class="m-nav__link m-dropdown__toggle">
                                    <span class="m-topbar__userpic">
                                       My Account
                                       <img
                                          src="https://keenthemes.com/metronic/preview/assets/app/media/img/users/user4.jpg"
                                          class="m--img-rounded m--marginless" alt="" />
                                    </span>
                                 </a>
                                 <div class="m-dropdown__wrapper">
                                    <span
                                       class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                                    <div class="m-dropdown__inner">
                                       <div class="m-dropdown__header m--align-center">
                                          <div class="m-card-user m-card-user--skin-dark">
                                             <div class="m-card-user__details">
                                                <span
                                                   class="m-card-user__name m--font-weight-500">Welcome<br>{{username}}</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="m-dropdown__body">
                                          <div class="m-dropdown__content">
                                             <ul class="m-nav">
                                                <!--<li class="m-nav__item">
                                                      <a href="#" class="m-nav__link">
                                                         <i class="m-nav__link-icon fas fa-user-tie"></i>
                                                         <span class="m-nav__link-title">
                                                            <span class="m-nav__link-wrap">
                                                               <span class="m-nav__link-text">Last Login</span>
                                                            </span>
                                                         </span>
                                                      </a>
                                                   </li>-->
                                                <!--<li class="m-nav__item">
                                                      <a href="#" class="m-nav__link">
                                                         <i class="m-nav__link-icon fas fa-unlock-alt"></i>
                                                         <span class="m-nav__link-text"><button *ngIf="userLoggedIn" (click)="cognitoChangePwd()">Change Password</button></span>
                                                      </a>
                                                   </li> -->
                                                <li class="m-nav__item">
                                                   <a href="#" class="m-nav__link">
                                                      <i class="m-nav__link-icon fas fa-sign-out-alt"></i>
                                                      <span class="m-nav__link m--font-weight-500" *ngIf="userLoggedIn"
                                                         (click)="cognitoSignOut()">Sign Out</span>
                                                   </a>
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-md-1">
                        <div id="m_header_topbar"
                           class="m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid pull-right-hard">
                           <div class="m-stack__item m-topbar__nav-wrapper">
                              <ul class="m-topbar__nav m-nav m-nav--inline">
                                 <li class="m-nav__item m-dropdown m-dropdown--large m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m-dropdown--skin-light	m-list-search m-list-search--skin-light"
                                    m-dropdown-toggle="click" id="m_quicksearch" m-quicksearch-mode="dropdown"
                                    m-dropdown-persistent="1">
                                    <a href="#" class="m-nav__link m-dropdown__toggle">
                                       <!-- <span class="menu-find">Find</span> <span class="m-nav__link-icon"><i class="fas fa-search"></i></span> -->
                                    </a>
                                    <div class="m-dropdown__wrapper">
                                       <span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
                                       <div class="m-dropdown__inner ">
                                          <div class="m-dropdown__header">
                                             <form class="m-list-search__form">
                                                <div class="m-list-search__form-wrapper">
                                                   <span class="m-list-search__form-input-wrapper">
                                                      <input id="m_quicksearch_input" type="text" name="q"
                                                         class="m-list-search__form-input" value=""
                                                         placeholder="Find by Mobile No. / IMEI No."
                                                         [(ngModel)]="textToBeSearch" (keydown.enter)="searchText()">
                                                   </span>
                                                   <span class="m-list-search__form-icon-close" id="m_quicksearch_close">
                                                      <i class="la la-remove"></i>
                                                   </span>
                                                </div>
                                             </form>
                                          </div>
                                       </div>
                                    </div>
                                 </li>
   
                              </ul>
                           </div>
                        </div>
                     </div>
                     <!-- END: Topbar -->
                     <!-- END: Topbar -->
                  </div>
               </div>
            </div>
         </div>
      </header>
      <!-- END: Header -->
      <!-- begin::Body -->
      <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
         <ngx-spinner type="line-spin-clockwise-fade">Loading..</ngx-spinner>
         <div class="m-grid__item m-grid__item--fluid m-wrapper">
            <div class="m-content">
               <div class="row">
                  <div class="col-7 col-lg-7 col-md-7 col-sm-7 col-xl-7"
                     [ngStyle]="{'height' : this.innerHeight <= 768 ? '480px' : '720px'}" style="overflow: auto">
                     <!--begin::Portlet-->
                     <div *ngIf="errorMessage!=null" class="alert alert-danger">
                        {{ errorMessage }}
                     </div>
                     
                     <div *ngIf="searchFl" class="m-portlet m-portlet--tabs">
                        <div class="m-portlet__body">
                           <div *ngIf="data_not_found" style="align-items: center">
                              <h5 class="m-form__heading-title" style="font: bold; color: crimson">No Data found for
                                 entered search text in CRM</h5>
                           </div>
                           <div *ngIf="fetchCRMError" style="align-items: center">
                              <h5 class="m-form__heading-title" style="font: bold; color: crimson">Error occured while
                                 fetching data from CRM. Please Refresh</h5>
                           </div>
                           <div
                              *ngIf="searchResult != null && searchResult.policies != null && searchResult.policies.length > 0"
                              class="mr-auto">
                              <!-- <h4 class="m-subheader__title m-subheader__title--separator">Checklist for Policy
                                 {{searchResult.policies[policyIndex].salesorder_no}}</h4> -->
                              <ul class="m-subheader__breadcrumbs m-nav m-nav--inline checklist">
                                 <li *ngIf="searchResult.policies[policyIndex].isPolicyInForce != null"
                                    class="m-nav__item">
                                    <span
                                       [class]="searchResult.policies[policyIndex].isPolicyInForce?  'label quadrat' : 'label label-success' ">
                                       Policy in force </span>
                                 </li>
                                 <li *ngIf="searchResult.policies[policyIndex].claims_exhausted != null"
                                    class="m-nav__item">
                                    <div *ngIf="searchResult.policies[policyIndex].claims_exhausted; else benefittemplate"
                                       class="quadrat label">
                                       <span>
                                          2 Request Registered </span>
                                    </div>
                                    <div *ngIf="searchResult.policies[policyIndex].total_claims==1">
                                       <span [class]="'label label-warning'">
                                          1 Request Registered </span>
                                    </div>
                                    <div *ngIf="searchResult.policies[policyIndex].total_claims==0">
                                       <span [class]="'label label-success'">
                                          0 Request Registered </span>
                                    </div>
                                 </li>
                                 <li *ngIf="searchResult.policies[policyIndex].policy_benefit_exhausted != null && !searchResult.policies[policyIndex].claims_exhausted"
                                    class="m-nav__item">
                                    <div *ngIf="searchResult.policies[policyIndex].policy_benefit_exhausted"
                                       class="quadrat label">
                                       <span>
                                          <i class="fa fa-rupee" style="font-size:13px"></i> 0 remaining </span>
                                    </div>
                                    <div
                                       *ngIf="searchResult.policies[policyIndex].policy_benefit_available_color=='amber'">
                                       <span [class]="'label label-warning'">
                                          <i class="fa fa-rupee" style="font-size:13px"></i>
                                          {{searchResult.policies[policyIndex].policy_benefit_available}} Remaining </span>
                                    </div>
                                    <div
                                       *ngIf="searchResult.policies[policyIndex].policy_benefit_available_color=='green'">
                                       <span [class]="'label label-success'">
                                          <i class="fa fa-rupee" style="font-size:13px"></i>
                                          {{searchResult.policies[policyIndex].policy_benefit_available}} Remaining </span>
                                    </div>
                                 </li>
                                 <li *ngIf="searchResult.policies[policyIndex].claimsExpiredFl != null"
                                    class="m-nav__item">
                                    <div *ngIf="searchResult.policies[policyIndex].claimsExpiredFl;else elsepart; "
                                       class="quadrat label">
                                       <span>
                                          Policy Expired</span>
                                    </div>
                                    <ng-template #elsepart>
                                       <div class="case">
                                          <span [class]="'label label-success'">
                                             Policy Active </span>
                                       </div>
                                    </ng-template>
                                 </li>
                                 <li *ngIf="searchResult.policies[policyIndex].policyStatus != null" class="m-nav__item">
                                    <div *ngIf="searchResult.policies[policyIndex].policyStatus=='Current'">
                                       <span [class]="'label label-success'">
                                          Policy Status- Current</span>
                                    </div>
                                    <div *ngIf="searchResult.policies[policyIndex].policyStatus=='New'">
                                       <span [class]="'label label-success'">
                                          Policy Status- New</span>
                                    </div>
                                    <div *ngIf="searchResult.policies[policyIndex].policyStatus=='Lapsed'" class="quadrat label">
                                       <span>
                                          Policy Status- Lapsed</span>
                                    </div>
                                    <div *ngIf="searchResult.policies[policyIndex].policyStatus=='Declined'"
                                       class="quadrat label">
                                       <span>
                                          Policy Status- Declined</span>
                                    </div>
   
   
                                 </li>
   
   
                                 <li *ngIf="searchResult.policies[policyIndex].clientName != null" class="m-nav__item">
                                    <span [class]="'label label-success'">
                                       {{searchResult.policies[policyIndex].clientName}}</span>
                                 </li>
   
                                 <li *ngIf="searchResult.policies[policyIndex].ragScore != null" class="m-nav__item">
                                    <span
                                       [class]="searchResult.policies[policyIndex].ragScore > 70 ?  'label label-danger' : 'label label-success' ">
                                       Claim RAG score {{ searchResult.policies[policyIndex].ragScore }}</span>
                                 </li>
                                 <li *ngIf="searchResult.policies[policyIndex].storeLocationScore != null"
                                    class="m-nav__item">
                                    <span
                                       [class]="searchResult.policies[policyIndex].storeLocationScore > 70 ?  'label label-danger' : 'label label-success' ">
                                       Store score {{ searchResult.policies[policyIndex].storeLocationScore }}</span>
                                 </li>
                              </ul>
                           </div>
   
                           <hr>
                           <ul class="nav nav-tabs" role="tablist">
                              <li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_1_tab_content"
                                    role="tab">
                                    <i class="fas fa-user"></i> Customer
                                 </a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link active  " data-toggle="tab" href="#m_portlet_base_demo_1_2_tab_content"
                                    role="tab">
                                    <i class="fas fa-file-alt"></i> Contract
                                 </a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_3_tab_content"
                                    role="tab">
                                    <i class="fas fa-file-invoice"></i> Service Request
                                 </a>
                              </li>
                              <!--<li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_4_tab_content"
                                    role="tab">
                                    <i class="fas fa-cog"></i> Activities
                                 </a>
                              </li>
                               <li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_5_tab_content" role="tab">
                                 <i class="fas fa-check-circle"></i> Checklist
                                 </a>
                                 </li> 
                              <li class="nav-item">
                                 <a class="nav-link" data-toggle="tab" href="#m_portlet_base_demo_1_6_tab_content"
                                    role="tab">
                                    <i class="fas fa-file-signature"></i> Notes
                                 </a>
                              </li>-->
                           </ul>
                           <div class="tab-content">
                              <div class="tab-pane" id="m_portlet_base_demo_1_1_tab_content" role="tabpanel">
                                 <div class="m-form__section">
                                    <div
                                       *ngIf="searchResult.customerInfo != null && searchResult.customerInfo.customerId != null">
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Name :</b>
                                             {{ getCustomerName(searchResult.customerInfo) }}</label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Email Id :</b>
                                             {{ searchResult.customerInfo.emailId }}</label>
                                       </div>
                                       <div class="row">
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>Alternate Phone No. :</b>
                                             {{ searchResult.customerInfo.alternateMobileNumber }}</label>
                                          <label class="col-xl-6 col-lg-6 col-form-label"><b>IMEI No. :</b>
                                             {{ getImeiNumber() }}</label>
                                       </div>
   
                                       <div class="m-separator m-separator--dashed m-separator--lg"></div>
   
                                    </div>
                                 </div>
                              </div>
                              <div class="tab-pane active" id="m_portlet_base_demo_1_2_tab_content" role="tabpanel">
                                 <div class="m-form__section">
                                    <ul class="nav nav-tabs" role="tablist">
                                       <li *ngFor="let result of searchResult.policies; let index=index"
                                          class="nav-item m-tabs__item"
                                          (click)="onPolicyClick(index,result)">
                                          <a *ngIf="index == 0" class="nav-link m-tabs__link active" data-toggle="tab"
                                             [href]="'#m_tabs_policy_6_'+ index" role="tab">{{ result.policyId }}
                                             {{result.policyType}}</a>
                                          <a *ngIf="index !=0" class="nav-link m-tabs__link" data-toggle="tab"
                                             [href]="'#m_tabs_policy_6_'+ index" role="tab">{{ result.policyId }}
                                             {{result.policyType}}</a>
                                       </li>
                                    </ul>
                                    <div class="tab-content">
                                       <div *ngFor="let result of searchResult.policies; let index=index"
                                          [class]="index == 0 ? 'tab-pane active' : 'tab-pane'"
                                          [id]="'m_tabs_policy_6_' + index" role="tabpanel">
   
                                          <h5 class="m-form__heading-title">Verification Details:</h5>
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Name :</b>
                                                {{ getCustomerName(searchResult.customerInfo) }}</label>
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Make :</b>
                                                {{ result.handsetMake }}</label>
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Email Id :</b>
                                                {{ result.email }}</label>
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Model :</b>
                                                {{ result.handsetModel }}</label>
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Telephone Number :</b>
                                                {{result.mobileNo }}
                                             </label>
   
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Purchase Date :</b>
                                                
                                                {{ result.mobilePurchaseDate | date:'dd-MMMM-yyyy' }}</label>
   
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-12 col-lg-12 col-form-label"><b>Address :</b>
                                                {{result.billingStreet}}
                                             </label>
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-12 col-lg-12 col-form-label"><b>City, Pin Code :</b>
                                                {{result.billingCity}} , {{result.billCode}}
                                             </label>
                                          </div>
                                          <div class="m-separator m-separator--dashed m-separator--lg"></div>
                                          <h5 class="m-form__heading-title">Policy Details:</h5>
   
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Start Date :</b>
                                                <span *ngIf="isDateGreaterThanToday(result.policyStartDate); else policyStartDate"
                                                   class="label quadrat">
                                                   {{ result.policyStartDate | date:'dd-MMMM-yyyy' }}</span>
                                                <ng-template #policyStartDate>
                                                      {{ result.policyStartDate | date:'dd-MMMM-yyyy' }}
                                                </ng-template>
                                             </label>
                                             
                                             <label class="col-xl-6 col-lg-6 col-form-label" ><b>End Date :</b>
                                                <span *ngIf="isDateLessThanToday(result.policyEndDate); else endDate"
                                                   class="label quadrat">
                                                   {{ result.policyEndDate | date:'dd-MMMM-yyyy' }}</span>&nbsp;&nbsp;
                                                <ng-template #endDate>
                                                   <span *ngIf="lessThanThirtyDays(result.policyEndDate)"
                                                      class="label label-warning">
                                                      {{ result.policyEndDate | date:'dd-MMMM-yyyy' }}</span>
                                                </ng-template>
                                                <span *ngIf="!lessThanThirtyDays(result.policyEndDate)">
                                                   {{ result.policyEndDate | date:'dd-MMMM-yyyy' }}</span>
                                             </label>
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Policy Type :</b>
                                                {{ result.policyType }}
                                             </label>
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Program Name :</b>
                                                {{ result.programType }}</label>
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Policy Status :</b>
                                                <span
                                                   *ngIf="isPolicyStatusOtherThanCurrent(result.status) ; else policyStatus"
                                                   class="quadrat label">
                                                   {{ result.policyStatus }}
                                                </span>
                                                <ng-template #policyStatus>{{result.policyStatus}}</ng-template>
                                             </label>
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Excess Fee :</b>
                                                <span class="label label-warning"> <i class="fa fa-rupee"
                                                      style="font-size:13px"></i>{{ result.policyExcessFee }} </span>
                                                      &nbsp;&nbsp;
                                                   </label>
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Device Category :</b>
                                                <span
                                                   *ngIf="result.deviceCategory=='Please select category' ; else deviceCategory">
   
                                                </span>
                                                <ng-template #deviceCategory>{{result.deviceCategory }}</ng-template>
                                             </label>
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>AMS Policy Id :</b>
                                                {{ result.policyId }}
                                             </label>
   
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Device Cost :</b>
                                                <i class="fa fa-rupee" style="font-size:13px"></i>
                                                {{ result.mobileCost }}
                                             </label>
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Balance Cover :</b>
                                                <i class="fa fa-rupee" style="font-size:13px"></i>
                                                {{ getBalanceConver(result) }}</label>
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Client Name :</b>
                                                {{ result.clientName }}</label>
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Underwriter Name :</b>
                                                {{ result.underwriterName ? result.underwriterName :  result.UnderwriterName }}</label>
                                          </div>
                                          <div class="row">
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Purchased On :</b>
   
                                                {{ result.mobilePurchaseDate | date:'dd-MMMM-yyyy' }}</label>
                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Premium Paid :</b>
                                                <i class="fa fa-rupee" style="font-size:13px"></i>
                                                {{ result.premiumAmountPaid }}
                                             </label>
                                          </div>
                                          <div class="row">
                                             <button class="ecertDownload col-xl-6 col-lg-6 col-form-label"  (click)="ecertDownload(true)" _ngcontent-c2="">Download Ecert</button>
                                             <button class="ecertResend col-xl-6 col-lg-6 col-form-label"  (click)="ecertDownload(false)" _ngcontent-c2="">Resend ecert to the customer</button>
                                          </div>
                                          <br><br><br>

                                          <!-- Activity information starts here -->
                                          <div class="m-accordion m-accordion--default activity-tab" id="m_accordion_00"
                                             role="tablist">
                                             <!--begin::Item-->
                                             <div class="m-accordion__item">
                                                <div class="m-accordion__item-head collapsed" role="tab"
                                                   id="m_accordion_00_item_00_head" data-toggle="collapse"
                                                   href="#m_accordion_00_item_00_body" aria-expanded="false">
                                                   <span class="m-accordion__item-title">Activity Information</span>
                                                   <span class="m-accordion__item-mode"></span>
                                                </div>
                                                <div class="m-accordion__item-body collapse"
                                                   id="m_accordion_00_item_00_body" role="tabpanel"
                                                   aria-labelledby="m_accordion_00_item_00_head"
                                                   data-parent="#m_accordion_00">
                                                   <div class="m-accordion__item-content">
                                                      <div class="m-form__section">
                                                         <table class="table">
                                                            <thead>
                                                               <tr>
                                                                  <th style="width: 20%" (click)="sorted()">Date/time <i
                                                                        [className]="sort==0 ? 'fas fa-sort-down' : 'fas fa-sort-up'"></i>
                                                                  </th>
                                                                  <th style="width: 20%">Description</th>
                                                                  <th style="width: 20%">Assigned To</th>
                                                                  <th style="width: 20%">Status</th>
                                                                  <th style="width: 20%">Priority</th>
                                                               </tr>
                                                            </thead>
                                                            <tbody>
                                                               <tr class="active"
                                                                  *ngFor="let comm of result.comments">
                                                                  <td style="width: 20%">
                                                                     {{ comm.createdDt | date:'dd-MMMM-yyyy HH:mm' }}
                                                                  </td>
                                                                  <td style="width: 20%"><span style="white-space: pre-line">{{ comm.description }}</span></td>
                                                                  <!-- <td style="width: 20%"><p>{{ comm.description }}</p></td> -->
                                                                  <td style="width: 20%">{{ comm.assignedTo }}</td>
                                                                  <td style="width: 20%">{{ comm.status }}</td>
                                                                  <td style="width: 20%">{{ comm.priority }}</td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
   
                                                         <div class="add-active">
                                                            <form>
                                                               <button class="btn btn-lg btn-add" (click)="onAddPolicyActivty()">Add
                                                                  Activity</button>
                                                               <div class="activity" *ngIf="tempPolicyComment != null">
                                                                  <div class="row">
                                                                     <div class="col-md-2">
                                                                        <label>Call Type <span class="required">*</span></label>
                                                                     </div>
                                                                     <div class="col-md-4">
                                                                        <div class="form-group">
                                                                           <select class="form-control" name="callType"  style="height: 40px" 
                                                                              [(ngModel)]="tempPolicyComment.callType">
                                                                              <option ng-value="403_Form not received">403_Form not received</option>
                                                                              <option ng-value="Address confirmation_AMT">Address confirmation_AMT</option>
                                                                              <option ng-value="Airway bill not received">Airway bill not received</option>
                                                                              <option ng-value="Alternate contact number_AMT">Alternate contact number_AMT</option>
                                                                              <option ng-value="Amtrust Call Back">Amtrust Call Back</option>
                                                                              <option ng-value="Amtrust Call Disconnected - Non Verified">Amtrust Call Disconnected - Non Verified</option>
                                                                              <option ng-value="Amtrust Dead Air">Amtrust Dead Air</option>
                                                                              <option ng-value="Amtrust Don’t want Claim">Amtrust Don’t want Claim</option>
                                                                              <option ng-value="Amtrust Early Termination">Amtrust Early Termination</option>
                                                                              <option ng-value="Amtrust General Enquiry">Amtrust General Enquiry</option>
                                                                              <option ng-value="Amtrust Invalid Number">Amtrust Invalid Number</option>
                                                                              <option ng-value="Amtrust LB-Kannada">Amtrust LB-Kannada</option>
                                                                              <option ng-value="Amtrust LB-Malayalam">Amtrust LB-Malayalam</option>
                                                                              <option ng-value="Amtrust LB-Tamil">Amtrust LB-Tamil</option>
                                                                              <option ng-value="Amtrust LB-Telugu">Amtrust LB-Telugu</option>
                                                                              <option ng-value="Amtrust Line Busy">Amtrust Line Busy</option>
                                                                              <option ng-value="Amtrust Make Call">Amtrust Make Call</option>
                                                                              <option ng-value="Amtrust No response from the caller">Amtrust No response from the caller</option>
                                                                              <option ng-value="Amtrust Odd tone">Amtrust Odd tone</option>
                                                                              <option ng-value="Amtrust Prank Calls">Amtrust Prank Calls</option>
                                                                              <option ng-value="Amtrust Request IMEI change">Amtrust Request IMEI change</option>
                                                                              <option ng-value="Amtrust Switched Off Number not Reachable">Amtrust Switched Off Number not Reachable</option>
                                                                              <option ng-value="Amtrust Temp out of Service">Amtrust Temp out of Service</option>
                                                                              <option ng-value="Amtrust Wrong number - IVR">Amtrust Wrong number - IVR</option>
                                                                              <option ng-value="Awaiting original documents from consumer_AMT">Awaiting original documents from consumer_AMT</option>
                                                                              <option ng-value="BER Settlement">BER Settlement</option>
                                                                              <option ng-value="Call Back to consumer_AMT">Call Back to consumer_AMT</option>
                                                                              <option ng-value="Call Dropped from Consumers end">Call Dropped from Consumers end</option>
                                                                              <option ng-value="CAT Callback">CAT Callback</option>
                                                                              <option ng-value="CAT- Odd Tone">CAT- Odd Tone</option>
                                                                              <option ng-value="CAT- Temp out of Service">CAT- Temp out of Service</option>
                                                                              <option ng-value="CAT Verification Done">CAT Verification Done</option>
                                                                              <option ng-value="CAT Verification Pending - Unavailability of Policy holder">CAT Verification Pending - Unavailability of Policy holder</option>
                                                                              <option ng-value="CAT-Invalid Number">CAT-Invalid Number</option>
                                                                              <option ng-value="CAT-Line Busy">CAT-Line Busy</option>
                                                                              <option ng-value="CAT-Not reachable/Switched off">CAT-Not reachable/Switched off</option>
                                                                              <option ng-value="CAT-Ringing No Response">CAT-Ringing No Response</option>
                                                                              <option ng-value="CAT-Wrong Number">CAT-Wrong Number</option>
                                                                              <option ng-value="Change in pick up address_AMT">Change in pick up address_AMT</option>
                                                                              <option ng-value="Claim cancelled by consumer_AMT">Claim cancelled by consumer_AMT</option>
                                                                              <option ng-value="Claim form not received_AMT">Claim form not received_AMT</option>
                                                                              <option ng-value="Declined - outsite policy guidelines_AMT">Declined - outsite policy guidelines_AMT</option>
                                                                              <option ng-value="Dispatch Status">Dispatch Status</option>
                                                                              <option ng-value="Email id required to send docs">Email id required to send docs</option>
                                                                              <option ng-value="Enquiry on Document sent">Enquiry on Document sent</option>
                                                                              <option ng-value="Enquiry on No Drop of claim cancelled_AMT">Enquiry on No Drop of claim cancelled_AMT</option>
                                                                              <option ng-value="Escalation - Call back_AMT">Escalation - Call back_AMT</option>
                                                                              <option ng-value="Excess fee Enquire_AMT">Excess fee Enquire_AMT</option>
                                                                              <option ng-value="Fresh Claim - Not Eligible">Fresh Claim - Not Eligible</option>
                                                                              <option ng-value="Handset not picked up_AMT">Handset not picked up_AMT</option>
                                                                              <option ng-value="HCI_EMI Related Calls">HCI_EMI Related Calls</option>
                                                                              <option ng-value="Intimation of Damage_AMT">Intimation of Damage_AMT</option>
                                                                              <option ng-value="Intimation of Theft_AMT">Intimation of Theft_AMT</option>
                                                                              <option ng-value="LB-Bengali">LB-Bengali</option>
                                                                              <option ng-value="LB-Gujarati">LB-Gujarati</option>
                                                                              <option ng-value="LB-Marathi">LB-Marathi</option>
                                                                              <option ng-value="LB-Oriya">LB-Oriya</option>
                                                                              <option ng-value="LB-Punjabi">LB-Punjabi</option>
                                                                              <option ng-value="List of documents - Enquiry_AMT">List of documents - Enquiry_AMT</option>
                                                                              <option ng-value="Others_AMT">Others_AMT</option>
                                                                              <option ng-value="Pick up status_AMT">Pick up status_AMT</option>
                                                                              <option ng-value="Pickup Done - Not in system">Pickup Done - Not in system</option>
                                                                              <option ng-value="PNA – CP - Awaiting bank Details from Customer">PNA – CP - Awaiting bank Details from Customer</option>
                                                                              <option ng-value="PNA – CP - Payment Pending">PNA – CP - Payment Pending</option>
                                                                              <option ng-value="PNA - CP - RWR Confirmed deliver to Customer">PNA - CP - RWR Confirmed deliver to Customer</option>
                                                                              <option ng-value="PNA - CP - RWR HS Dispatched">PNA - CP - RWR HS Dispatched</option>
                                                                              <option ng-value="PNA – CP - RWR HS pending For Dispatch">PNA – CP - RWR HS pending For Dispatch</option>
                                                                              <option ng-value="PNA – CUSTOMER READY TO WAIT- Repair">PNA – CUSTOMER READY TO WAIT- Repair</option>
                                                                              <option ng-value="PNA – CUSTOMER READY TO WAIT- Technical repor">PNA – CUSTOMER READY TO WAIT- Technical repor</option>
                                                                              <option ng-value="PNA - SR - Pending Self Repair Documents from customer">PNA - SR - Pending Self Repair Documents from customer</option>
                                                                              <option ng-value="PNA - SR - RWR Confirmed deliver to Customer">PNA - SR - RWR Confirmed deliver to Customer</option>
                                                                              <option ng-value="PNA - SR - RWR HS Dispatched">PNA - SR - RWR HS Dispatched</option>
                                                                              <option ng-value="PNA – SR - RWR HS pending For Dispatch">PNA – SR - RWR HS pending For Dispatch</option>
                                                                              <option ng-value="Policy not in CRM">Policy not in CRM</option>
                                                                              <option ng-value="Post Repair Issue">Post Repair Issue</option>
                                                                              <option ng-value="Repair on consumers end_AMT">Repair on consumers end_AMT</option>
                                                                              <option ng-value="Request Anti-Theft Features">Request Anti-Theft Features</option>
                                                                              <option ng-value="Reschedule of Pickup_AMT">Reschedule of Pickup_AMT</option>
                                                                              <option ng-value="Ringing No Response">Ringing No Response</option>
                                                                              <option ng-value="Status of Handset - Replacement phone_AMT">Status of Handset - Replacement phone_AMT</option>
                                                                              <option ng-value="Status of Handset - Under Repair_AMT">Status of Handset - Under Repair_AMT</option>
                                                                              <option ng-value="Status of Handset - with U/W_AMT">Status of Handset - with U/W_AMT</option>
                                                                              <option ng-value="Status on Replacement of Phone_AMT">Status on Replacement of Phone_AMT</option>
                                                                              <option ng-value="Status_verification of docs">Status_verification of docs</option>
                                                                              <option ng-value="System Issue">System Issue</option>
                                                                              <option ng-value="TestCall_AMT">TestCall_AMT</option>
                                                                              <option ng-value="Wrong Number">Wrong Number</option>

                                                                           </select>
                                                                        </div>
                                                                     </div>
                                                                     <div class="col-md-2">
                                                                        <label>Priority</label>
                                                                     </div>
                                                                     <div class="col-md-4">
                                                                        <div class="form-group">
                                                                           <select class="form-control" name="statusPol"  style="height: 40px" 
                                                                              [(ngModel)]="tempPolicyComment.priority" ng-init="Low">Low
                                                                              <option ng-value="Low">Low</option>
                                                                              <option ng-value="Medium">Medium</option>
                                                                              <option ng-value="High">High</option>
                                                                           </select>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="row">
                                                                     <div class="col-md-2">
                                                                        <label>Description <span class="required">*</span></label>
                                                                     </div>
                                                                     <div class="col-md-10">
                                                                        <div class="form-group required" >
                                                                           <textarea class="form-control" rows="6"
                                                                              name="description"
                                                                              [(ngModel)]="tempPolicyComment.description"  ng-required="true"></textarea>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="row">
                                                                     <div class="col-md-6">
                                                                        <div class="row">
                                                                           <div class="col-md-4">
                                                                              <label>Assigned to</label>
                                                                           </div>
                                                                           <div class="col-md-8">
                                                                              <div class="form-group">
                                                                                 <select class="form-control"  style="height: 40px" 
                                                                                    name="callType"
                                                                                    [(ngModel)]="tempPolicyComment.assignedTo">
                                                                                    <option ng-value="-">-</option>
                                                                                    <option ng-value="Underwriter">
                                                                                       Underwriter</option>
                                                                                    <option ng-value="Logistics">Logistics
                                                                                    </option>
                                                                                    <option ng-value="Support">Support
                                                                                    </option>
                                                                                    <option ng-value="Replacement">
                                                                                       Replacement</option>
                                                                                    <option ng-value="Post Repair">Post
                                                                                       Repair</option>
                                                                                    <option ng-value="Repair">Repair
                                                                                    </option>
                                                                                    <option
                                                                                       ng-value="Internal Escalation Team">
                                                                                       Internal Escalation Team</option>
                                                                                    <option ng-value="Sales Team">Sales
                                                                                       Team</option>
                                                                                    <option ng-value="Linguistic Calls">
                                                                                       Linguistic Calls</option>
                                                                                    <option ng-value="Laptop Claims">Laptop
                                                                                       Claims</option>
                                                                                    <option ng-value="BI Team">BI Team</option>
                                                                                 </select>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                     <div class="col-md-6">
                                                                        <div class="row">
                                                                           <div class="col-md-4">
                                                                              <label>Status</label>
                                                                           </div>
                                                                           <div class="col-md-8">
                                                                              <div class="form-group">
                                                                                 <select class="form-control"  style="height: 40px" 
                                                                                    name="priorityPol"
                                                                                    [(ngModel)]="tempPolicyComment.status" ng-init="Open">Open
                                                                                    <option ng-value="Open">Open
                                                                                    </option>
                                                                                    <option ng-value="Escalated">Escalated</option>
                                                                                    
                                                                                    <option ng-value="Close">Close</option>
                                                                                    
                                                                                 </select>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="row">
                                                                     <div class="col-md-12">
                                                                        <div class="active-btn">
                                                                           <button class="btn btn-primary btn-clear"
                                                                              type="reset">Clear</button>
                                                                           <button class="btn btn-success btn-in"
                                                                              (click)="savePolicyComment(result)">Add</button>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </form>
                                                         </div>
   
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <!--end::Item-->
                                          </div>
                                          <!-- Activity information ends here -->
   
   
                                       </div>
   
   
   
   
   
   
                                       <!--Policy Tab2-->
                                       <!--End Policy Tab2-->
                                    </div>
                                 </div>
                              </div>
                              <!--Service Request start-->
                              <div class="tab-pane" id="m_portlet_base_demo_1_3_tab_content" role="tabpanel">
                                 <div class="m-form__section">
   
                                    <ul class="nav nav-tabs" role="tablist">
                                       <li *ngFor="let result of claimResult; let index=index"
                                          class="nav-item m-tabs__item">
                                          <a *ngIf="index == 0" class="nav-link m-tabs__link active" data-toggle="tab"
                                             [href]="'#m_tabs_claim_6_'+ index" role="tab">
                                             {{ result.arcContract }}</a>
                                          <a *ngIf="index !=0" class="nav-link m-tabs__link" data-toggle="tab"
                                             [href]="'#m_tabs_claim_6_'+ index" role="tab">
                                             {{ result.arcContract }}</a>
                                       </li>
                                    </ul>
                                 </div>
                                 <div class="tab-content">
                                    <div *ngFor="let claim of claimResult; let index=index"
                                       [class]="index == 0 ? 'tab-pane active' : 'tab-pane'"
                                       [id]="'m_tabs_claim_6_' + index" role="tabpanel">
                                       <!--begin claim 01-->
   
                                       <!-- <aw-wizard>
                                          <aw-wizard-step stepTitle="Reported">
   
                                          </aw-wizard-step>
                                          <aw-wizard-step stepTitle="L1 Approval">
   
                                          </aw-wizard-step>
                                          <aw-wizard-step stepTitle="Documents">
   
                                          </aw-wizard-step>
                                          <aw-wizard-step stepTitle="Pickup">
   
                                          </aw-wizard-step>
                                          <aw-wizard-step stepTitle="L2 Approval">
   
                                          </aw-wizard-step>
                                          <aw-wizard-step stepTitle="Repair">
   
                                          </aw-wizard-step>
                                          <aw-wizard-step stepTitle="Dispatched">
   
                                          </aw-wizard-step>
                                          <aw-wizard-step stepTitle="Delivered ">
   
                                          </aw-wizard-step>
                                       </aw-wizard> -->
                                       <br>
                                       <div class="m-accordion m-accordion--default" id="m_accordion_1" role="tablist">
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_1_head"  data-toggle="collapse"
                                                href="#m_accordion_1_item_1_body" aria-expanded="false">
                                                <span class="m-accordion__item-title">Status</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body open" id="m_accordion_1_item_1_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_1_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
   
   
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Claim Status
                                                               :</b>
                                                            {{ claim.claimStatus }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Status :</b>
                                                            {{ claim.status }}</label>
                                                      </div>
                                                      <div class="row">
                                                         
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>TAT Start Date
                                                               :</b>
   
                                                            {{ claim.tatStartDate | date:'dd-MMMM-yyyy' }} </label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>TAT End Date
                                                                  :</b>
      
                                                               {{ claim.tatEndDate | date:'dd-MMMM-yyyy' }} </label>
                                                      </div>
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Aging :</b>
                                                               {{ claim.aging }} </label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Phone number :</b>
                                                               {{ claim.homeContract }} </label>
                                                            
                                                        
                                                      </div>
                                                      
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="m-accordion m-accordion--default" id="m_accordion_1" role="tablist">
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_20_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_20_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Activity Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_20_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_20_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
   
                                                      <!-- Activity information starts here -->
   
                                                      <!--begin::Item-->
   
                                                      <table class="table">
                                                         <thead>
                                                            <tr>
                                                               <th style="width: 20%" (click)="sorted()">Date/time <i
                                                                     [className]="sort==0 ? 'fas fa-sort-down' : 'fas fa-sort-up'"></i>
                                                               </th>
                                                               <th>Description</th>
                                                               <!-- <th>Assigned To</th>
                                                               <th>Status</th>
                                                               <th>Priority</th> -->
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr
                                                               *ngFor="let comm of claim.comments">
                                                               <td>{{ comm.createdDt | date:'dd-MMMM-yyyy HH:mm:SS' }}
                                                               </td>
                                                               <td><span style="white-space: pre-line">{{ comm.description }}</span></td>
                                                               <!-- <td>{{ comm.assignedTo }}</td>
                                                               <td>{{ comm.status }}</td>
                                                               <td>{{ comm.priority }}</td> -->
                                                            </tr>
                                                         </tbody>
                                                      </table>
   
                                                      <div class="add-active">
                                                         <form>
                                                            <button class="btn btn-lg btn-add" (click)="onAddClaimActivty()">Add
                                                               Activity</button>
                                                            <div [id]="'claimactivity' + claim.id" class="activity" *ngIf="tempClaimComment != null">
                                                               <div class="row">
                                                                  <div class="col-md-2">
                                                                     <label>Call Type <span class="required">*</span></label>
                                                                  </div>
                                                                  <div class="col-md-4">
                                                                     <div class="form-group">
                                                                        <select class="form-control" name="callType"   style="height: 40px" 
                                                                           [(ngModel)]="tempClaimComment.callType">
                                                                           <option ng-value="403_Form not received">403_Form not received</option>
                                                                           <option ng-value="Address confirmation_AMT">Address confirmation_AMT</option>
                                                                           <option ng-value="Airway bill not received">Airway bill not received</option>
                                                                           <option ng-value="Alternate contact number_AMT">Alternate contact number_AMT</option>
                                                                           <option ng-value="Amtrust Call Back">Amtrust Call Back</option>
                                                                           <option ng-value="Amtrust Call Disconnected - Non Verified">Amtrust Call Disconnected - Non Verified</option>
                                                                           <option ng-value="Amtrust Dead Air">Amtrust Dead Air</option>
                                                                           <option ng-value="Amtrust Don’t want Claim">Amtrust Don’t want Claim</option>
                                                                           <option ng-value="Amtrust Early Termination">Amtrust Early Termination</option>
                                                                           <option ng-value="Amtrust General Enquiry">Amtrust General Enquiry</option>
                                                                           <option ng-value="Amtrust Invalid Number">Amtrust Invalid Number</option>
                                                                           <option ng-value="Amtrust LB-Kannada">Amtrust LB-Kannada</option>
                                                                           <option ng-value="Amtrust LB-Malayalam">Amtrust LB-Malayalam</option>
                                                                           <option ng-value="Amtrust LB-Tamil">Amtrust LB-Tamil</option>
                                                                           <option ng-value="Amtrust LB-Telugu">Amtrust LB-Telugu</option>
                                                                           <option ng-value="Amtrust Line Busy">Amtrust Line Busy</option>
                                                                           <option ng-value="Amtrust Make Call">Amtrust Make Call</option>
                                                                           <option ng-value="Amtrust No response from the caller">Amtrust No response from the caller</option>
                                                                           <option ng-value="Amtrust Odd tone">Amtrust Odd tone</option>
                                                                           <option ng-value="Amtrust Prank Calls">Amtrust Prank Calls</option>
                                                                           <option ng-value="Amtrust Request IMEI change">Amtrust Request IMEI change</option>
                                                                           <option ng-value="Amtrust Switched Off Number not Reachable">Amtrust Switched Off Number not Reachable</option>
                                                                           <option ng-value="Amtrust Temp out of Service">Amtrust Temp out of Service</option>
                                                                           <option ng-value="Amtrust Wrong number - IVR">Amtrust Wrong number - IVR</option>
                                                                           <option ng-value="Awaiting original documents from consumer_AMT">Awaiting original documents from consumer_AMT</option>
                                                                           <option ng-value="BER Settlement">BER Settlement</option>
                                                                           <option ng-value="Call Back to consumer_AMT">Call Back to consumer_AMT</option>
                                                                           <option ng-value="Call Dropped from Consumers end">Call Dropped from Consumers end</option>
                                                                           <option ng-value="CAT Callback">CAT Callback</option>
                                                                           <option ng-value="CAT- Odd Tone">CAT- Odd Tone</option>
                                                                           <option ng-value="CAT- Temp out of Service">CAT- Temp out of Service</option>
                                                                           <option ng-value="CAT Verification Done">CAT Verification Done</option>
                                                                           <option ng-value="CAT Verification Pending - Unavailability of Policy holder">CAT Verification Pending - Unavailability of Policy holder</option>
                                                                           <option ng-value="CAT-Invalid Number">CAT-Invalid Number</option>
                                                                           <option ng-value="CAT-Line Busy">CAT-Line Busy</option>
                                                                           <option ng-value="CAT-Not reachable/Switched off">CAT-Not reachable/Switched off</option>
                                                                           <option ng-value="CAT-Ringing No Response">CAT-Ringing No Response</option>
                                                                           <option ng-value="CAT-Wrong Number">CAT-Wrong Number</option>
                                                                           <option ng-value="Change in pick up address_AMT">Change in pick up address_AMT</option>
                                                                           <option ng-value="Claim cancelled by consumer_AMT">Claim cancelled by consumer_AMT</option>
                                                                           <option ng-value="Claim form not received_AMT">Claim form not received_AMT</option>
                                                                           <option ng-value="Declined - outsite policy guidelines_AMT">Declined - outsite policy guidelines_AMT</option>
                                                                           <option ng-value="Dispatch Status">Dispatch Status</option>
                                                                           <option ng-value="Email id required to send docs">Email id required to send docs</option>
                                                                           <option ng-value="Enquiry on Document sent">Enquiry on Document sent</option>
                                                                           <option ng-value="Enquiry on No Drop of claim cancelled_AMT">Enquiry on No Drop of claim cancelled_AMT</option>
                                                                           <option ng-value="Escalation - Call back_AMT">Escalation - Call back_AMT</option>
                                                                           <option ng-value="Excess fee Enquire_AMT">Excess fee Enquire_AMT</option>
                                                                           <option ng-value="Fresh Claim - Not Eligible">Fresh Claim - Not Eligible</option>
                                                                           <option ng-value="Handset not picked up_AMT">Handset not picked up_AMT</option>
                                                                           <option ng-value="HCI_EMI Related Calls">HCI_EMI Related Calls</option>
                                                                           <option ng-value="Intimation of Damage_AMT">Intimation of Damage_AMT</option>
                                                                           <option ng-value="Intimation of Theft_AMT">Intimation of Theft_AMT</option>
                                                                           <option ng-value="LB-Bengali">LB-Bengali</option>
                                                                           <option ng-value="LB-Gujarati">LB-Gujarati</option>
                                                                           <option ng-value="LB-Marathi">LB-Marathi</option>
                                                                           <option ng-value="LB-Oriya">LB-Oriya</option>
                                                                           <option ng-value="LB-Punjabi">LB-Punjabi</option>
                                                                           <option ng-value="List of documents - Enquiry_AMT">List of documents - Enquiry_AMT</option>
                                                                           <option ng-value="Others_AMT">Others_AMT</option>
                                                                           <option ng-value="Pick up status_AMT">Pick up status_AMT</option>
                                                                           <option ng-value="Pickup Done - Not in system">Pickup Done - Not in system</option>
                                                                           <option ng-value="PNA – CP - Awaiting bank Details from Customer">PNA – CP - Awaiting bank Details from Customer</option>
                                                                           <option ng-value="PNA – CP - Payment Pending">PNA – CP - Payment Pending</option>
                                                                           <option ng-value="PNA - CP - RWR Confirmed deliver to Customer">PNA - CP - RWR Confirmed deliver to Customer</option>
                                                                           <option ng-value="PNA - CP - RWR HS Dispatched">PNA - CP - RWR HS Dispatched</option>
                                                                           <option ng-value="PNA – CP - RWR HS pending For Dispatch">PNA – CP - RWR HS pending For Dispatch</option>
                                                                           <option ng-value="PNA – CUSTOMER READY TO WAIT- Repair">PNA – CUSTOMER READY TO WAIT- Repair</option>
                                                                           <option ng-value="PNA – CUSTOMER READY TO WAIT- Technical repor">PNA – CUSTOMER READY TO WAIT- Technical repor</option>
                                                                           <option ng-value="PNA - SR - Pending Self Repair Documents from customer">PNA - SR - Pending Self Repair Documents from customer</option>
                                                                           <option ng-value="PNA - SR - RWR Confirmed deliver to Customer">PNA - SR - RWR Confirmed deliver to Customer</option>
                                                                           <option ng-value="PNA - SR - RWR HS Dispatched">PNA - SR - RWR HS Dispatched</option>
                                                                           <option ng-value="PNA – SR - RWR HS pending For Dispatch">PNA – SR - RWR HS pending For Dispatch</option>
                                                                           <option ng-value="Policy not in CRM">Policy not in CRM</option>
                                                                           <option ng-value="Post Repair Issue">Post Repair Issue</option>
                                                                           <option ng-value="Repair on consumers end_AMT">Repair on consumers end_AMT</option>
                                                                           <option ng-value="Request Anti-Theft Features">Request Anti-Theft Features</option>
                                                                           <option ng-value="Reschedule of Pickup_AMT">Reschedule of Pickup_AMT</option>
                                                                           <option ng-value="Ringing No Response">Ringing No Response</option>
                                                                           <option ng-value="Status of Handset - Replacement phone_AMT">Status of Handset - Replacement phone_AMT</option>
                                                                           <option ng-value="Status of Handset - Under Repair_AMT">Status of Handset - Under Repair_AMT</option>
                                                                           <option ng-value="Status of Handset - with U/W_AMT">Status of Handset - with U/W_AMT</option>
                                                                           <option ng-value="Status on Replacement of Phone_AMT">Status on Replacement of Phone_AMT</option>
                                                                           <option ng-value="Status_verification of docs">Status_verification of docs</option>
                                                                           <option ng-value="System Issue">System Issue</option>
                                                                           <option ng-value="TestCall_AMT">TestCall_AMT</option>
                                                                           <option ng-value="Wrong Number">Wrong Number</option>

                                                                        </select>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-2">
                                                                     <label>Priority</label>
                                                                  </div>
                                                                  <div class="col-md-4">
                                                                     <div class="form-group">
                                                                        <select class="form-control" name="priorityClaim"  style="height: 40px" 
                                                                           [(ngModel)]="tempClaimComment.priority" ng-init="Low">Low
                                                                           <option ng-value="Low" >Low</option>
                                                                           <option ng-value="Medium">Medium</option>
                                                                           <option ng-value="High">High</option>
                                                                        </select>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="row">
                                                                  <div class="col-md-2">
                                                                     <label for="required">Description <span class="required">*</span></label>
                                                                  </div>
                                                                  <div class="col-md-10">
                                                                     <div class="form-group">
                                                                        <textarea class="form-control" rows="6"
                                                                           name="description"
                                                                           [(ngModel)]="tempClaimComment.description" ng-required="required"></textarea>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="row">
                                                                  <div class="col-md-6">
                                                                     <div class="row">
                                                                        <div class="col-md-4">
                                                                           <label>Assigned to</label>
                                                                        </div>
                                                                        <div class="col-md-8">
                                                                           <div class="form-group">
                                                                              <select class="form-control" name="assignedTo"  style="height: 40px" 
                                                                                 [(ngModel)]="tempClaimComment.assignedTo">
                                                                                 <option ng-value="-">-</option>
                                                                                 <option ng-value="Underwriter">Underwriter
                                                                                 </option>
                                                                                 <option ng-value="Logistics">Logistics
                                                                                 </option>
                                                                                 <option ng-value="Support">Support
                                                                                 </option>
                                                                                 <option ng-value="Replacement">Replacement
                                                                                 </option>
                                                                                 <option ng-value="Post Repair">Post Repair
                                                                                 </option>
                                                                                 <option ng-value="Repair">Repair</option>
                                                                                 <option
                                                                                    ng-value="Internal Escalation Team">
                                                                                    Internal Escalation Team</option>
                                                                                 <option ng-value="Sales Team">Sales Team
                                                                                 </option>
                                                                                 <option ng-value="Linguistic Calls">
                                                                                    Linguistic Calls</option>
                                                                                 <option ng-value="Laptop Claims">Laptop
                                                                                    Claims</option>
                                                                                    <option ng-value="BI Team">BI Team</option>
                                                                              </select>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-6">
                                                                     <div class="row">
                                                                        <div class="col-md-4">
                                                                           <label>Status</label>
                                                                        </div>
                                                                        <div class="col-md-8">
                                                                           <div class="form-group">
                                                                              <select class="form-control" name="statusClaim"  style="height: 40px" 
                                                                                 [(ngModel)]="tempClaimComment.status" ng-init="Open">Open
                                                                                 <option ng-value="Open" >Open
                                                                                 </option>
                                                                                 <option ng-value="Escalated">Escalated
                                                                                 </option>
                                                                                 <option ng-value="Close">Close</option>
                                                                              </select>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="row">
                                                                  <div class="col-md-12">
                                                                     <div class="active-btn">
                                                                        <button class="btn btn-primary btn-clear"
                                                                           type="reset" (click)="clearForm()">Clear</button>
                                                                        <button class="btn btn-success btn-in"
                                                                           (click)="saveClaimComments(claim)">Add</button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </form>
                                                      </div>
   
   
                                                      <!--end::Item-->
   
                                                      <!-- Activity information ends here -->
   
   
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_3_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_3_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Intimation
                                                   Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_3_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_3_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Subject
                                                               :</b>
                                                            {{ claim.subject }}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Created Time
                                                                  :</b>
                                                               {{ claim.createdtime | date:'dd-MMMM-yyyy HH:mm:SS' }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Home Contact
                                                               :</b>
                                                            {{ claim.homeContract }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Email :</b>
                                                            {{ claim.emailId }} </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Complete
                                                               Description :</b>
   
                                                            {{ claim.completeDescription }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Date of
                                                               Incident
   
                                                               :</b>
                                                            {{ claim.incidentDate  | date:'dd-MMMM-yyyy'}}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Incident Time :</b>

                                                               {{ claim.timeOfIncident }}
                                                            </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Damage Type
                                                               :</b>
                                                            {{ claim.damageType }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Claim Type
                                                               :</b>
                                                            {{ claim.claimType }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Claim Cause
                                                               :</b>
                                                            {{ claim.claimCause }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Delay in Intimation
                                                               :</b>
                                                            {{ claim.delayInClaimIntimation }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Alternate Phone Number:
                                                               :</b>
                                                            {{ claim.alternatePhoneNumber }}</label>
                                                         
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_8_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_8_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Damage Questionnaire</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_8_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_8_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Any Liquid
                                                            Damage
                                                            :</b>
                                                         {{ claim.anyLiquidDamage }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Is the device
                                                            powering on? :</b>
                                                         {{ claim.isScreenCracked }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Is the screen
                                                            cracked? :</b>
                                                         {{ claim.isScreenCracked }} </label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>* Are there any
                                                            other
                                                            cosmetic damages on the back panel or other areas? :</b>
                                                         {{ claim.isCosmeticDamage }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>What is the
                                                            color
                                                            of
                                                            the device? :</b>
                                                         {{ claim.colorOfDevice }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>What is the
                                                            memory
                                                            capacity? :</b>
                                                         {{ claim.memorycapacity }}</label>
                                                   </div>
                                                   <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Is the phone
                                                            locked?
                                                            :</b>
                                                         {{ claim.isPhoneLocked }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Lock Code
                                                            :</b>
                                                         {{ claim.lockCodeDeacription }}</label>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_2_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_2_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Documents Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_2_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_2_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>TBD :</b>
                                                            {{ claim.tbd }}</label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_5_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_5_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Service Fee Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_5_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_5_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Applicable
                                                               Fee
                                                               :</b>
                                                            <i class="fa fa-rupee" style="font-size:13px"></i>
                                                            {{ claim.applicableFee }}
                                                         </label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Payment
                                                               status
                                                               :</b>
                                                            {{ claim.paymentstatus }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Fee paid by
                                                               customer :</b>
                                                            {{ claim.feePaidByCustomer }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Payment date
                                                               :</b>
                                                            <span *ngIf="claim.paymentDate">
                                                            {{ claim.paymentDate | date:'dd-MMMM-yyyy' }}
                                                         </span></label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Transaction
                                                               reference :</b>
                                                            {{ claim.serviceTransactionRef }}</label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_13_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_13_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">L1 Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_13_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_13_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Underwriter
                                                               Name
                                                               :</b>
                                                            {{ claim.underwriterName  }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Status
                                                               :</b>
                                                            {{ claim.l1status }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Sub Status
                                                               :</b>
                                                            {{ claim.l1SubStatus }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Rejection
                                                               Reason
                                                               :</b>
                                                            {{ claim.l1Rejection }}</label>
                                                      </div>
   
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Queried by
                                                               :</b>
                                                            {{ claim.l1QueriedByUnderwriterTS }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Respond from
                                                               Underwriter TS
                                                               :</b>
                                                            {{ claim.l1ResponeFrmUnderTs }}</label>
                                                      </div>
   
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Query
                                                               Resolved
                                                               Date TS :</b>
                                                            {{ claim.l1QueryResolvedDateTS | date:'dd-MMMM-yyyy'}} </label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Queried by
                                                               underwriter TS :</b>
                                                            {{ claim.comment }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 TAT Response
                                                               :</b>
                                                            {{ claim.l1TatResponse }}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Query Resolved Comment by Ams :</b>
      
                                                               {{ claim.l1QueryResolvedCommentByAms }}</label>
                                                      </div>
   
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 TAT for
                                                                  Query Resolved vs Final Response :</b>
                                                                     {{ claim.l1TatForQueryResolvedVsFinalResponse }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Raised vs Query
                                                               Resolved
                                                               :</b>
                                                            {{ claim.raisedVsQueryResolved }}</label>
                                                        
                                                      </div>
   
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Manager
                                                                  Comment
                                                                  :</b>
                                                               {{ claim.l1ManagerComment }} </label>

                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 verify
                                                               dropdown
                                                               :</b>
                                                            {{ claim.l1VerifyDrop }}</label>
   
                                                         
                                                      </div>
                                                      
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>L1
                                                                  Investigation
                                                                  :</b>
                                                               {{ claim.l1Investigation }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L1 Calling date
                                                               :</b>
                                                            {{ claim.l1CallingDt  | date:'dd-MMMM-yyyy'}}</label>
                                                         </div>
                                                         <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>L1 Verify
                                                               Remarks :</b>
                                                            {{ claim.l1VerifyRemarks }} </label>
                                                      </div>
                                                      
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_6_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_6_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Logistic Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_6_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_6_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup AWB
                                                               Number :</b>
                                                            {{ claim.pickupAwbNumber }}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup LSP
                                                                  Name :</b>
                                                               {{ claim.pickupLspName }}</label>
                                                      </div>
                                                      <div class="row">
                                                         
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup LSP
                                                                  Response :</b>
                                                               {{ claim.pickupLspResponse }}</label>
                                                               <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup LSP
                                                                     Rejection reason :</b>
                                                                  {{ claim.pickupLspRejection }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup
                                                               Rescheduled
                                                               :</b>
                                                            {{ claim.pickupReschedule }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup
                                                               Reschedule
                                                               count :</b>
                                                            {{ claim.pickupRescheduleCount }} </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup Schedule
                                                         Date :</b>
                                                      {{ claim.pickupScheduleDate | date:'dd-MMMM-yyyy' }}
                                                   </label>
                                                   <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup
                                                         Reschedule
                                                         Date :</b>

                                                      {{ claim.pickupRescheduleDate | date:'dd-MMMM-yyyy' }}</label>
                                                   </div>
                                                   <div class="row">
			
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Delivered to
                                                               ARC
                                                               Date :</b>
   
                                                            {{ claim.delieveredToArcDate | date:'dd-MMMM-yyyy' }}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Actual Pickup Date :</b>
      
                                                               {{ claim.actualPickupDate | date:'dd-MMMM-yyyy' }}</label>
                                                         
                                                      </div>
                                                      <hr>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch
                                                               AWB
                                                               Number :</b>
                                                            {{ claim.dispatchAwbNumber }}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch
                                                                  LSP
                                                                  Name :</b>
                                                               {{ claim.dispatchLspName }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch
                                                               LSP
                                                               Response :</b>
                                                            {{ claim.dispatchLspResponse }} </label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch
                                                                  Rescheduled :</b>
                                                               {{ claim.dispatchReschedule }} </label>
                                                      </div>
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>RTO/Lost Date
                                                                  :</b>
                                                               {{ claim.rtoDate | date:'dd-MMMM-yyyy' }}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Handset
                                                                  Dispatch
                                                                  Date :</b>
      
                                                               {{ claim.handsetDispathDate | date:'dd-MMMM-yyyy' }}</label>
                                                         </div>
                                                         <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Actual Delivery
                                                                  to
                                                                  customer Date :</b>
      
                                                               {{ claim.actualDeliveryToCustomerDate | date:'dd-MMMM-yyyy' }}
                                                            </label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch
                                                                  Reschedule count :</b>
      
                                                               {{ claim.dispatchRescheduleCount }}</label>
                                                         </div>
                                                      
                                                      <hr>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>FOM Core
                                                               Status
                                                               :</b>
                                                            {{ claim.fomCoreStatus }}</label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
   
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_4_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_4_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Pickup/Drop Address</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_4_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_4_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Pickup
                                                               Street/Area :</b>
                                                            {{ claim.pickupArea }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Pickup Unit #
                                                               /
                                                               Floor / House no. / Block / Landmark :</b>
                                                            {{ claim.pickupUnit }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup City
                                                               :</b>
                                                            {{ claim.pickupCity }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup State
                                                               :</b>
                                                            {{ claim.pickupState }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup Pin Code
                                                               :</b>
                                                            {{ claim.pinCode }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Pickup Country
                                                               :</b>
                                                            {{ claim.country }}</label>
                                                      </div>
                                                      <hr>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Dispatch
                                                               Street/Area :</b>
                                                            {{ claim.dispatchArea }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Dispatch Unit
                                                               #
                                                               / Floor / House no. / Block / Landmark :</b>
                                                            {{ claim.dispatchUnit }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch City
                                                               :</b>
                                                            {{ claim.dispatchCity }} </label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch State
                                                               :</b>
                                                            {{ claim.dispatchState }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch Pin
                                                               Code
                                                               :</b>
                                                            {{ claim.dispatchPincode }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Dispatch
                                                               Country
                                                               :</b>
                                                            {{ claim.dispatchCountry }}</label>
                                                      </div>
   
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
   
   
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_7_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_7_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">ARC Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_7_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_7_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>ARC Name
                                                               :</b>
                                                            {{ claim.arcName }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>ARC
                                                               Address
                                                               :</b>
                                                            {{ claim.arcAddress }} </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>ARC City
                                                               :</b>
                                                            {{ claim.arcCity }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>ARC state
                                                               :</b>
                                                            {{ claim.arcState }} </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>ARC PIN code
                                                               :</b>
                                                            {{ claim.arcPinCode }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>ARC Contact
                                                               :</b>
                                                            {{ claim.arcContact }} </label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_19_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_19_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">L2 Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_19_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_19_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Underwriter
                                                               Name
                                                               :</b>
                                                            {{ claim.l2underwriterName }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 submitted to
                                                               underwriter :</b>
                                                            {{ claim.l2SubmittedToUnderwriter | date:'dd-MMMM-yyyy'}}</label>
                                                      </div>
   
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Respond from
                                                               Underwriter :</b>
                                                            {{ claim.l2respondFrmUnder }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 sub status
                                                               :</b>
                                                            {{ claim.l2SubStatus }}</label>
                                                      </div>
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Respond from Underwriter TS
                                                                  :</b>
                                                               {{ claim.l2ResponeFrmUnderTS | date:'dd-MMMM-yyyy'}}</label>

                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Queried by
                                                               Underwriter Ts :</b>
                                                            {{ claim.l2QueriedByUnderwriterTS | date:'dd-MMMM-yyyy'}}</label>
                                                        
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Query
                                                                  Resolved
                                                                  Ts
                                                                  :</b>
                                                               {{ claim.l2QueryResolvedTS | date:'dd-MMMM-yyyy'}}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Query
                                                                     Comment
                                                                     :</b>
                                                                  {{ claim.l2QueryComment }}</label>
                                                      </div>
                                                      <div class="row">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Query Resolved Comment by Ams
                                                                  :</b>
                                                               {{ claim.l2QueryResolvCommByAms }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Remarks by
                                                               u/w
                                                               :</b>
                                                            {{ claim.l2RemarksByUW }}</label>
                                                         
                                                      </div>
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Approved Amount :</b>
                                                               <i class="fa fa-rupee" style="font-size:13px"></i> {{  claim.approvedAmnt }} </label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 submitted to
                                                               underwriter time :</b>
   
                                                            {{ claim.l2SubmittedToUnderwriterTime | date:'dd-MMMM-yyyy'}}</label>
                                                       
                                                      </div>
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 TAT Response
                                                                  :</b>
                                                               {{ claim.l2TatResponse }}</label>
                                                         <label class="col-xl-12 col-lg-6 col-form-label"><b>L2 TAT for
                                                               Query
                                                               Resolved vs Final Response :</b>
   
                                                            {{ claim.l2TatForQueryResolvedVsFinalResponse }}</label>
                                                      </div>
                                                      
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Write Off
                                                               Reason
                                                               :</b>
                                                            {{ claim.writeOffReason }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Written Off
                                                               Date
                                                               :</b>
                                                            {{ claim.writtenOfDate }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Drop Off
                                                               Comment
                                                               :</b>
                                                            {{ claim.dropOffComment }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Verify
                                                               Dropdown
                                                               :</b>
                                                            {{ claim.l2VerifyDropdown }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Manager
                                                               Comment
                                                               :</b>
                                                            {{ claim.l2ManagerComment }} </label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Salvage Amount
                                                               :</b>
                                                            {{ claim.salvageAmnt }} </label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Self
                                                               Endorsement
                                                               :</b>
                                                            {{ claim.selfEndorsement }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Verify
                                                               Remarks
                                                               :</b>
                                                            {{ claim.l2verifyRemarks }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Fraud Remark
                                                               Comment :</b>
                                                            {{ claim.fraudRemarkComment }}</label>

                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Fraud Remark TS  :</b>
                                                               {{ claim.fraudRemarTs }}</label>
   
                                                         
                                                      </div>
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>L2 Decline
                                                                  Reason
                                                                  :</b>
                                                               {{ claim.l2DeclinedReason }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Query Raised by
                                                               CAT
                                                               :</b>
                                                            {{ claim.queryRarisedByCat }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>BSC Catch Up
                                                               :</b>
                                                            {{ claim.bscCatchUp }}</label>
                                                      </div>

                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_9_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_9_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Repair</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_9_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_9_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Repair
                                                               Completed :</b>
   
                                                            {{ claim.isRepairCompletd | date:'dd-MMMM-yyyy'}}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Repair
                                                               Status
                                                               :</b>
                                                            {{ claim.repairStatus }}</label>
                                                      </div>

                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Request Status :</b>
                                                               {{ claim.repairRequestStatus }}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Approved to Start Repair :</b>
                                                               {{ claim.repairApprToStrtRepai}}
                                                            </label>
                                                         </div>

                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Repair
                                                               Invoice
                                                               Number :</b>
                                                            {{ claim.repairInvoiceNumber }}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Repair Invoice Date :</b>
                                                               {{ claim.repairInvoiceDt| date:'dd-MMMM-yyyy' }}
                                                            </label>
                                                      </div>
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Expected
                                                                  Repair
                                                                  Completed Date :</b>
                                                               {{ claim.expectedRepairCompletedDate| date:'dd-MMMM-yyyy' }}
                                                            </label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Repair Type :</b>
                                                               {{ claim.repairType }}
                                                            </label>
                                                         
                                                      </div>
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Invoice
                                                                  status
                                                                  :</b>
                                                               {{ claim.invoiceStatus }}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Actual
                                                                  Repair/Replacement Amount:</b>
      
                                                               {{ claim.actualReplacementAmount }}</label>
                                                        
                                                      </div>
                                                      <div class="row">
                                                           
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Revised
                                                               Expected
                                                               Repair Completed Date :</b>
   
                                                            {{ claim.revisedExpectedRepairCompletedDate | date:'dd-MMMM-yyyy'}}</label>
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Handset Dispatched Date :</b>
      
                                                               {{ claim.handsetDispathDate | date:'dd-MMMM-yyyy'}}</label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
   
                                          <!--begin::Item-->
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_11_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_11_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Replacement Status</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_11_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_11_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                         <div class="row">
                                                               <label class="col-xl-6 col-lg-6 col-form-label"><b>BER
                                                                     :</b>
                                                                  {{ claim.ber }}
                                                               </label>
                                                               <label class="col-xl-6 col-lg-6 col-form-label"><b>Phone Collected
                                                                     :</b>
                                                                  {{ claim.phoneCollected }} </label>
                                                            </div>
                                                            
                                                      <div class="row">
                                                            <label class="col-xl-6 col-lg-6 col-form-label"><b>Salvage :</b>
      
                                                               {{ claim.salvage}}</label>
                                                             <label class="col-xl-6 col-lg-6 col-form-label"><b>Handset Dispatched Date :</b>
      
                                                               {{ claim.handsetDispatchedDt | date:'dd-MMMM-yyyy'}}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-12 col-lg-12 col-form-label"><b>Repair Status :</b>
                                                            {{ claim.repairStatus }}</label>
                                                         
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <!--<div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_12_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_12_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Theft Claim</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_12_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_12_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Complete
                                                               Document
                                                               Received Date :</b>
                                                            {{ claim.docRecivedDate }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Customer
                                                               Notified
                                                               Date :</b>
                                                            {{ claim.customerNotifiedDate }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Theft TAT
                                                               :</b>
                                                            {{ claim.theftTat }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Photo Id
                                                               (Theft)
                                                               :</b>
                                                            {{ claim.photoId }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Sim Blocking
                                                               :</b>
                                                            {{ claim.simBlocking }}</label>
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Completed claim
                                                               form
                                                               with signature (Theft) :</b>
   
                                                            {{ claim.isFormCompletedWithSignature }}</label>
                                                      </div>
                                                      <div class="row">
                                                         <label class="col-xl-6 col-lg-6 col-form-label"><b>Claim Discharge
                                                               Voucher :</b>
                                                            {{ claim.claimDischargeVoucher }}</label>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>-->
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <!--
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_10_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_10_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Replacement</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_10_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_10_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>BER :</b>
                                                         {{ claim.ber }}</label>
                                                      <label class="col-xl-6 col-lg-6 col-form-label"><b>Phone Collected
                                                            :</b>
                                                         {{ claim.phoneCollected }} </label>
                                                   </div>
                                                   
                                                </div>
                                             </div>
                                          </div> -->
                                          <!--end::Item-->
                                          <!--begin::Item
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_15_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_15_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Comments Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_15_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_15_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <p><strong>Test Claims</strong></p>
                                                   <p>Author: Siddhesh Khot on 11-10-2018 18:06:41</p>
                                                   <hr>
                                                   <p><strong>As this is the ICICI cases so its been WRITE OFF as per the
                                                         management approval - Pratik</strong></p>
                                                   <p>Author: Sharik Qureshi on 11-10-2018 18:06:41</p>
                                                   <hr>
                                                </div>
                                             </div>
                                          </div>-->
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <!--<div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_16_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_16_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Claim SMS History</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_16_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_16_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <p>{{ claim.claimSMSHistory }}</p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>-->
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <!--<div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_17_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_17_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Claim Email History</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_17_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_17_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <p>{{ claim.claimEmailHistory }}</p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>-->
                                          <!--end::Item-->
                                          <!--begin::Item
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_18_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_18_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Tracking Information</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_18_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_18_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <p>{{ claim.tractingInformation }}</p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>-->
                                          <!--end::Item-->
                                          <!--begin::Item-->
                                          <!--
                                          <div class="m-accordion__item">
                                             <div class="m-accordion__item-head collapsed" role="tab"
                                                id="m_accordion_1_item_19_head" data-toggle="collapse"
                                                href="#m_accordion_1_item_19_body" aria-expanded="    false">
                                                <span class="m-accordion__item-title">Change History</span>
                                                <span class="m-accordion__item-mode"></span>
                                             </div>
                                             <div class="m-accordion__item-body collapse" id="m_accordion_1_item_19_body"
                                                role="tabpanel" aria-labelledby="m_accordion_1_item_19_head"
                                                data-parent="#m_accordion_1">
                                                <div class="m-accordion__item-content">
                                                   <div class="m-form__section">
                                                      <p>{{ claim.changeHistory }}</p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>-->
                                          <!--end::Item-->
                                       </div>
                                       <!--end::Section-->
                                    </div>
                                 </div>
                              </div>
                              <div class="tab-pane" id="m_portlet_base_demo_1_4_tab_content" role="tabpanel">
                                 <div
                                    *ngIf="searchResult.activity != null && searchResult.activity.episodeChats.length > 0"
                                    class="m-accordion m-accordion--default" id="m_accordion_activities_1" role="tablist">
                                    <!-- single activity -->
                                    <div *ngFor="let episodeChat of searchResult.activity.episodeChats; let index=index;"
                                       class="m-accordion__item">
                                       <div class="m-accordion__item-head collapsed" role="tab"
                                          [id]="'m_accordion_1_activities_item_' + index + '_head'" data-toggle="collapse"
                                          [attr.href]="'#m_accordion_1_activities_item_' + index + '_body'"
                                          aria-expanded="false">
                                          <span class="m-accordion__item-title">Conversation {{ index + 1 }}</span>
                                          <span class="m-accordion__item-mode"></span>
                                       </div>
                                       <div class="m-accordion__item-body collapse"
                                          [id]="'m_accordion_1_activities_item_' + index + '_body'" role="tabpanel"
                                          [attr.aria-labelledby]="'m_accordion_1_activities_item_' + index + '_head'"
                                          data-parent="#m_accordion_activities_1">
                                          <div class="m-accordion__item-content">
                                             <div class="m-messenger m-messenger--message-arrow m-messenger--skin-light">
                                                <div class="m-messenger__messages m-scrollable">
                                                   <div *ngFor="let message of episodeChat.chatMessages.messages">
                                                      <div *ngIf="message.from == 'AUTO'" class="m-messenger__wrapper">
                                                         <div class="m-messenger__message m-messenger__message--in">
                                                            <div class="m-messenger__message-pic">
                                                               <img src="./assets/images/user3.png" alt="" />
                                                            </div>
                                                            <div class="m-messenger__message-body">
                                                               <div class="m-messenger__message-arrow"></div>
                                                               <div class="m-messenger__message-content">
                                                                  <div class="m-messenger__message-username">
                                                                     Auto wrote
                                                                  </div>
                                                                  <div class="m-messenger__message-text">
                                                                     {{ message.messageText }}
                                                                  </div>
                                                               </div>
                                                               <div class="m-messenger__datetime">
                                                                  {{ getStringDateFromMillis(message.messageTime.$date) }}
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div *ngIf="message.from != 'AUTO'" class="m-messenger__wrapper">
                                                         <div class="m-messenger__message m-messenger__message--out">
                                                            <div class="m-messenger__message-body">
                                                               <div class="m-messenger__message-arrow"></div>
                                                               <div class="m-messenger__message-content">
                                                                  <div
                                                                     *ngIf="message.displayText != null && message.displayText.trim().length != 0"
                                                                     class="m-messenger__message-text">
                                                                     {{ message.displayText }}
                                                                  </div>
                                                                  <div
                                                                     *ngIf="message.displayText == null || message.displayText.trim().length == 0"
                                                                     class="m-messenger__message-text">
                                                                     {{ message.messageText }}
                                                                  </div>
                                                               </div>
                                                               <div class="m-messenger__datetime">12:30PM</div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="m-messenger__seperator"></div>
                                                <div class="m-messenger__form">
                                                   <div class="m-messenger__form-controls">
                                                      <input type="text" readonly name="" placeholder="Type here..."
                                                         class="m-messenger__form-input">
                                                   </div>
                                                   <div class="m-messenger__form-tools">
                                                      <a href="" class="m-messenger__form-attachment">
                                                         <i class="la la-paperclip"></i>
                                                      </a>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <!-- <div class="tab-pane" id="m_portlet_base_demo_1_5_tab_content" role="tabpanel">
                  <h4 class="m-form__heading-title">Checklist</h4>
                  <div class="m-section__content">
                     <ul class="ul-style1">
                        <li>Policy in force</li>
                        <li>Claims exhausted</li>
                        <li>Benefits exhausted</li>
                        <li>Store Location Score</li>
                     </ul>
                  </div>
                  </div> -->
                              <div class="tab-pane" id="m_portlet_base_demo_1_6_tab_content" role="tabpanel">
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                 incididunt
                                 ut
                                 labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                 ullamco
                                 laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                                 in
                                 voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                 cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                              </div>
                           </div>
                        </div>
   
                        <!--end::Portlet-->
                     </div>
                     <!--Right side-->
                  </div>
                  <div class="col-5 col-lg-5 col-md-5 col-sm-5 col-xl-5"
                     [style.height]="this.innerHeight <= 768 ? '480px' : '720px'">
                     <iframe  [ngStyle]="{'margin-top': '0px', 'height': '600px', 'display' : loadWorkbench ? 'block' : 'block', 'height': this.innerHeight <= 768 ? '480px' : '720px'}" #autoBot (click)="virtualAgentUrl"
                        [src]="virtualAgentUrl" class="iframeStyle" frameborder="0"></iframe>
                     <!-- <iframe [style.height]="this.innerHeight <= 768 ? '600px' : '720px'" #autoBot
                        (click)="virtualAgentUrl" [src]="virtualAgentUrl" class="iframeStyle" frameborder="0"></iframe> -->
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end:: Body -->
      <!-- end:: Page -->
      <!-- begin::Scroll Top -->
   </div>
      <div id="m_scroll_top" class="m-scroll-top"> <i class="la la-arrow-up"></i> </div>
      <!-- end::Scroll Top -->
</div>
      <div style="position: absolute; left: 10px; bottom: 30px;">
         <eco-fab-speed-dial>
            <eco-fab-speed-dial-trigger>
               <button style="color: #3E7BC7" mat-fab (click)="doAction('menu')">
                  <mat-icon>menu</mat-icon>
               </button>
            </eco-fab-speed-dial-trigger>
            <eco-fab-speed-dial-actions>
               <button mat-mini-fab (click)="newChat()" title="New Chat">
                  <mat-icon>add</mat-icon>
               </button>
               <button mat-mini-fab  (click)="open(content)" title="Exception Activity">
                  <mat-icon>question_answer</mat-icon>
               </button>
               <button mat-mini-fab (click)="refreshChat()" title="refresh chat">
                  <mat-icon>refresh</mat-icon>
               </button>
               <button mat-mini-fab (click)="clearCache()" title="Clear data">
                  <mat-icon>delete</mat-icon>
               </button>
            </eco-fab-speed-dial-actions>
         </eco-fab-speed-dial>
      </div>

     
    <!-- Activity information starts here -->
   
                                                      <!--begin::Item-->
                                                      <ng-template #content let-modal>
                                                         <div class="modal-header">
                                                           <h4 class="modal-title" id="modal-basic-title">Exception Activity</h4>
                                                           <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                             <span aria-hidden="true">&times;</span>
                                                           </button>
                                                         </div>
                                                         <div class="modal-body">
                                                           
                                          
                                                      <!-- Activity information starts here -->
   
                                                      <!--begin::Item-->
   
                                                         <form>
                                                               <div class="row">
                                                                     <div class="form-group col-md-12">
                                                                        
                                                                        <div class="col-md-2">
                                                                           <label>Mobile/IMEI <span class="required">*</span></label>
                                                                        </div>
                                                                        <div class="col-md-10">
                                                                              <input type="text" [(ngModel)]="tempExceptionalCommnet.imeiphonenumber"   id="commentsmobileimei" name="imeiphonenumber" class="col-md-10">
                                                                           
                                                                        </div>
                                                                     </div>
                                                                     
                                                                  </div>
                                                               <div class="row">
                                                                     <div class="form-group col-md-12">
                                                                        <div class="col-md-2">
                                                                           <label>Call Type <span class="required">*</span></label>
                                                                        </div>
                                                                        <div class="col-md-10">
                                                                           
                                                                              <select class="form-control" name="callType"
                                                                                 [(ngModel)]="tempExceptionalCommnet.callType" style="height: 40px">
                                                                                 <option ng-value="Policy not found in CRM">Policy not found in CRM</option>
                                                                                 <option ng-value="Customer unable to provide details">Customer unable to provide details</option>
                                                                                 <option ng-value="Language Barrier">Language Barrier</option>
                                                                                 <option ng-value="Others">Others</option>
                                                                              </select>
                                                                           
                                                                        </div>
                                                                  </div>
                                                                  
                                                               </div>
                                                               <div class="row">
                                                                     <div class="form-group col-md-12">
                                                                        <div class="col-md-2">
                                                                           <label for="required">Description <span class="required">*</span></label>
                                                                        </div>
                                                                        <div class="col-md-10">
                                                                           
                                                                              <textarea class="form-control" rows="6"
                                                                                 name="description" style="height: 60px"
                                                                                 [(ngModel)]="tempExceptionalCommnet.description" ng-required="required"></textarea>
                                                                           
                                                                        </div>
                                                                  </div>
                                                               </div>
                                                               <div class="row">
                                                                     <div class="form-group col-md-12">
                                                                 <div class="col-md-2">
                                                                           <label>Assigned to</label>
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                           
                                                                              <select class="form-control" style="height: 40px" name="callType"
                                                                                 [(ngModel)]="tempExceptionalCommnet.assignedTo" syle="height:20px">
                                                                                 <option ng-value="-">-</option>
                                                                                 <option ng-value="Underwriter">Underwriter
                                                                                 </option>
                                                                                 <option ng-value="Logistics">Logistics
                                                                                 </option>
                                                                                 <option ng-value="Support">Support
                                                                                 </option>
                                                                                 <option ng-value="Replacement">Replacement
                                                                                 </option>
                                                                                 <option ng-value="Post Repair">Post Repair
                                                                                 </option>
                                                                                 <option ng-value="Repair">Repair</option>
                                                                                 <option
                                                                                    ng-value="Internal Escalation Team">
                                                                                    Internal Escalation Team</option>
                                                                                 <option ng-value="Sales Team">Sales Team
                                                                                 </option>
                                                                                 <option ng-value="Linguistic Calls">
                                                                                    Linguistic Calls</option>
                                                                                 <option ng-value="Laptop Claims">Laptop
                                                                                    Claims</option>
                                                                                    <option ng-value="BI Team">BI Team</option>
                                                                              </select>
                                                                           
                                                                        </div>
                                                                    
                                                                        <div class="col-md-2">
                                                                           <label>Status</label>
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                           
                                                                              <select class="form-control" style="height: 40px" name="status"
                                                                                 [(ngModel)]="tempExceptionalCommnet.status" ng-init="Open">Open
                                                                                 <option ng-value="Open" >Open
                                                                                 </option>
                                                                                 <option ng-value="Escalated">Escalated
                                                                                 </option>
                                                                                 <option ng-value="Close">Close</option>
                                                                              </select>
                                                                           
                                                                        </div>
                                                                     
                                                                  
                                                                  <div class="col-md-2">
                                                                     <label>Priority</label>
                                                                  </div>
                                                                  <div class="col-md-2">
                                                                     
                                                                        <select class="form-control" style="height: 40px" name="priority"
                                                                           [(ngModel)]="tempExceptionalCommnet.priority" ng-init="Low">Low
                                                                           <option ng-value="Low" >Low</option>
                                                                           <option ng-value="Medium">Medium</option>
                                                                           <option ng-value="High">High</option>
                                                                        </select>
                                                                     
                                                                  </div>
                                                                  </div>
                                                               </div>
                                                               <div class="row">
                                                                  <div class="col-md-12">
                                                                     <div class="active-btn">
                                                                        <button class="btn btn-primary btn-clear"
                                                                           type="reset" (click)="clearForm()">Clear</button>
                                                                        <button class="btn btn-success btn-in"
                                                                           (click)="saveException()">Add</button>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            
                                                         </form>
                                                      
                                                         
                                                   

                                                         
                                                         </div>
                                                       </ng-template>
                                                       
                                                       
                                                      <!--end::Item-->