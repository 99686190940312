export const environment = {
  production: true,

  // apiurl: 'https://api.automatapi.io',
  apiurl: 'https://api.automatapi.com',
  searchApi: '/amtrust/cti/fetchdata',
  policies: '/api/secondcrmindia/transformpoliciesbyimei',
  claims: '/api/secondcrmindia/transformclaimbyimei',
  policyclaimdetails: '/api/secondcrmindia/policyclaimsdetails',
  customerbyid: '/api/secondcrmindia/transformcustomerbyid',
  comments: '/api/secondcrmindia/comments',
  saveComment: '/amtrust/secondcrmindia/saveclaimcomments',
  savePolicyComment: '/amtrust/savepolicycomments',
  getMessages: '/getMessages',
  virtualAgentUrl: 'https://workbench.automatapi.com/#/wb/5c8a2ffecdc0530001afb728?displaySideBar=false',
  // virtualAgentUrl:'https://workbench.automatapi.io/#/wb/5cb0b4caf32f55000131ccfb?displaySideBar=false',
  companyId: 'e95764c923e74e308d0019516b17cabd',
  bearertoken: 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJhbXRydXN0IiwiaWF0IjoxNTEyNzM0NjA5LCJzdWIiOiJhbXRydXN0IiwiaXNzIjoiNDRlM2VjNWVjMzE5NGI0Yjg2Mjk2MTg4MTg0NDU0ODEiLCJuYmYiOjAsImV4cCI6NDEwMjMzODYwMH0.maFFgdZFgKGlCCAmfu6Yb3Aruy6ERxaDdEeO5wquoT4',
  // ******* AWS Properties ******* //
  region: 'ap-south-1',

  //userPoolId: 'ap-south-1_CHh5zZo1Y',
  //clientId: '13792pc8li623cka6e88993jk8',
  userPoolId: 'ap-south-1_nc0aHeJkq',
  clientId: '5shksgl03hu3sb34p4r1vd534o',

  cognito_idp_endpoint: '',

  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: '',
  // ******* AWS Properties ******* //

  // ******* AWS Endpoints ******* //
  awsServerUrl: 'https://amtrust-agent.auth.ap-south-1.amazoncognito.com',
  awsDynamoCrud: 'testing/dynamo/crud',
  // ******* AWS Endpoints ******* //

  serverUrl: 'https://demo-console.automatapi.com/',
  // serverUrl: 'https://demo-console.automatapi.io/',
  flowUrl: 'flow/api/withState/',
  // ******* API Endpoints ******* //
  ecertCompanyId:'admin',

  x_Api_Key:'pp3exLKtIr64kr7Sf5ZYf5nrHEEmERkA2z0A35BQ',

  awsurl:'https://pn4mt2v0b6.execute-api.ap-south-1.amazonaws.com/',
  // awsurl:'https://f9s9fzyxv6.execute-api.ap-south-1.amazonaws.com/',


  policiesForEcertApi:'testing/api/amtrust/pollpoliciesforecert'
  // policiesForEcertApi:'production/api/amtrust/pollpoliciesforecert'
};
