export class SearchRequest {
    imei: string;
    phoneNumber: string;
    companyContext: any;
    clearCache: boolean;
    userName: string;
    policyId: string [];


    constructor() {
        this.policyId=[];
        this.imei = null;
        this.phoneNumber = null;
        this.companyContext = {};
        this.clearCache = false;
        this.userName = null;
    }
}