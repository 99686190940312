export class CustomerInfo {
    _id: string = null;
    alternateMobileNumber: string = null;
    assignedTo: string = null;
    billingCity: string = null;
    billingCountry: string = null;
    billingPostalCode: string = null;
    billingState: string = null;
    billingStreet: string = null;
    createdDt: number = 0;
    createdTime: number = 0;
    customerId: string = null;
    customerNumber: string = null;
    description: string = null;
    firstName: string = null;
    isPortalUser: string = null;
    lastModifiedBy: string = null;
    lastName: string = null;
    modifiedTime: number = 0;
    phoneNumber: string = null;
    portalPassowrd: string = null;
}