import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
//import { Subscription } from 'rxjs/Subscription';
import { UserLoginService } from '../../services/aws/user-login.service';
import { CognitoUtil } from "../../services/aws/cognito.service";
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { Subscription, from } from "rxjs";
import * as moment from 'moment/moment';

import { Home } from '../../models/home';
import { SearchResponse } from '../../models/searchresponse.model'
import { SuccessResponse } from '../../models/success.model'
import { Claim } from '../../models/claim.model'
import { Activity, EpisodeChat } from '../../models/activity.model';
import { EventData } from '../../models/event.model'


import { SearchService } from '../../services/search.service'
import { SaveServices } from '../../services/save.services'
import { ConversationService } from '../../services/conversation.service'
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval } from 'rxjs';
import { Comments } from '../../models/comments.model';
import { DatePipe } from '@angular/common';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Policy } from '../../models/policy.model';
import { CustomerInfo } from '../../models/customerInfo.model';
import { SearchRequest } from '../../models/searchrequest.model';
import { link } from 'fs';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('autoBot') autoBotRefId: ElementRef;

  userLoggedIn: boolean;
  selectedClientName: string = '';
  selectedPolicyNo: string = '';
  searchFl: boolean = false;
  session: CognitoUserSession;
  enteredIMEINo: string = '';
  loadWorkbench: boolean = false;
  innerHeight: any;
  closeResult: string;
  
  
  searchResult: SearchResponse;
  claimResult: Claim[] = [];
  saveResponse: SuccessResponse;
  textToBeSearch: string;
  claimComment: string;
  policyIndex: number = 0;
  commentsForm: Comments;
  commentsFormdescription: string = null;
  commentsFormassignTo: string = null;
  commentsFormpriority: string = 'Low';
  commentsFormstatus: string = 'Open';
  commentsFormcallType: string = null;
  commentsmobileimei: string =null;
  virtualAgentUrl = null;

  private searchSubscription: Subscription;
  private conversationSubscriptions: Subscription;
  user: any;
  username: string;
  cti_imei: string;
  data_not_found: boolean;
  fetchCRMError: boolean = true;
  sort: number = 0;
  tempPolicyComment: Comments;
  tempClaimComment: Comments;
  tempExceptionalCommnet: Comments;
  policyResultSuccess: boolean = false;
  claimResultSuccess: boolean = false;
  clearDataFromCache: boolean = false;
  searchRequest: SearchRequest= null;
  errorMessage: string = null;


  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,

    private route: ActivatedRoute,
    public userService: UserLoginService,
    private cognitoUtil: CognitoUtil,
    private searchService: SearchService,
    private saveService: SaveServices,
    private conversationService: ConversationService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private modalService: NgbModal
  ) {
    this.sort = 0;
    this.tempPolicyComment = null;
    this.tempClaimComment = null;
    this.tempExceptionalCommnet = null;
    this.searchResult = new SearchResponse();
    this.searchRequest = new SearchRequest();
    window.addEventListener('message', (event) => {
      console.log("amtrust listen event");
      //console.log(event.data);
      if (event && event.data) {
        const eventData = event.data;
        if (eventData && eventData.eventId && eventData.eventId == "IMEI") {
          this.textToBeSearch = eventData.messageText;
          this.searchText();
        }
        if (eventData && eventData.eventId && eventData.eventId == "IMEI_MULTIPLE") {
          if (eventData.formResult && eventData.formResult.selectedImei) {
            this.textToBeSearch = eventData.formResult.selectedImei;
            this.searchText();
          }
        }
        
      }

    }
    )
  }

  home: Home = {
    email: '',
    name: '',
    mobile: '',
    alternatemobile: '',
    imei: '',
    policy_imei: '',
    policyid: '',
    bill_add: '',
    policystatus: '',
    make: '',
    model: '',
    mobilecost: '',
    excessfee: '',
    policystartdate: '',
    policyenddate: '',
    mpd: '',
    pp: '',
    clientname: '',
    protectiontype: '',
    policytype: '',
    programtype: '',
    programname: '',
    underwritername: '',
    policy_imei2: '',
    policyid2: '',
    bill_add2: '',
    policystatus2: '',
    make2: '',
    model2: '',
    mobilecost2: '',
    excessfee2: '',
    policystartdate2: '',
    policyenddate2: '',
    mpd2: '',
    pp2: '',
    clientname2: '',
    protectiontype2: '',
    policytype2: '',
    programtype2: '',
    programname2: '',
    underwritername2: ''
  };



  ngOnInit() {
    // this.textToBeSearch = "9819430048";
    // this.searchText();
    this.cognitoValidateSession();
    this.cti_imei = this.route.snapshot.paramMap.get("imei");
    if (this.cti_imei != null) {
        this.textToBeSearch = this.cti_imei;
        this.searchText();
        this.loadWorkbench = true;
        this.errorMessage = null;
      
      
    }
    else {
      this.loadWorkbench = true;
    }
    this.innerHeight = window.screen.height;
    console.log("IMEI Received in the URL:" + this.cti_imei);
  }

  // Activity information starts here
  active: number = 0;
  activity() {
    this.active = 1;
    this.tempPolicyComment = new Comments();
    this.tempPolicyComment.userName = this.username;
    this.tempPolicyComment.imei = this.getImeiNumber();
  }

  ecertDownload(smsFlag){
    let linkChk;
    this.policyResultSuccess = false;
    this.claimResultSuccess = false;
    console.log("---policyId for ecert download-"+this.searchResult.policies[0].policyId);
    this.spinner.show();
    this.searchSubscription = this.searchService.getEcertByImei(this.getImeiNumber(),smsFlag,this.searchResult.policies[0].policyId)
      .subscribe(
        response => {
          if (response && response.length && response.length > 0) {
              linkChk=response[0].pdfLink;
              console.log(JSON.stringify(response));
              console.log("----linkChk---"+linkChk);
              this.spinner.hide();
              if(linkChk!=null){
                window.open(linkChk);
              }else{
                alert("premium band not config!");
              }
          }
        },
        error => {
          console.log("error");
          console.log(error);
        }
      )
  }

  onAddClaimActivty() {
    this.tempClaimComment = new Comments();
    this.tempClaimComment.userName = this.username;
    this.tempClaimComment.imei = this.getImeiNumber();
    
  }

  onAddPolicyActivty() {
    this.tempPolicyComment = new Comments();
    this.tempPolicyComment.userName = this.username;
    this.tempPolicyComment.imei = this.getImeiNumber();
  }
  
  sorted() {
    if (this.sort == 0)
      this.sort = 1;
    else
      this.sort = 0;
  }
  // Activity information ends here

  doAction(action) {
    console.log(action);
  }
  cognitoSignIn() {
    this.router.navigate(['/pg/login']);
  }

  refresh(): void {
    window.location.reload();
    }

  cognitoSignUp() {
    this.router.navigate(['/pg/register']);
  }

  cognitoChangePwd() {
    this.router.navigate(['/pg/login']);
  }

  cognitoSignOut() {
    if (this.userLoggedIn) {
      this.userService.logout();
      this.cognitoValidateSession();
    }
    this.router.navigate(['/pg/login']);
  }

  getPolicies() {
    this.searchSubscription = this.searchService.getPoliciesByImei(this.searchRequest)
            .subscribe(
              response => {
                // console.log("policies");
                // console.log(response);
                this.spinner.hide();
                if (!response && response.length == 0){
                  return;
                }
                console.log("*********************");
                console.log(this.searchRequest);
                if (this.searchRequest.phoneNumber != null) {
                  if (this.isMoreThanOneImei(response)) {
                    console.log(this.searchRequest.phoneNumber);
                    console.log(this.isMoreThanOneImei(response));
                    this.errorMessage = "There are more than one imeis for provided phone number";
                    return;
                  }
                  else {
                    this.errorMessage = null;
                    this.searchRequest.imei = response[0].imei.trim();
                    
                  }
                }
                this.searchResult.policies = response;
                this.getClaims();
                this.loadWorkbench = true;
                
                if (this.searchResult.policies.length > 0) {
                    this.onclickpolicytab(this.searchResult.policies[0]);
                    this.selectedClientName = this.searchResult.policies[0].clientName;
                    this.selectedPolicyNo = this.searchResult.policies[0].policyId;
                    this.searchFl = true;
                    if(!this.searchResult.customerInfo || this.searchResult.customerInfo == null || !this.searchResult.customerInfo._id) {
                      this.getCustomer(this.searchResult.policies[0].customerId);
                    }
                    this.policyResultSuccess = true;
                    
                    if (this.policyResultSuccess && this.claimResultSuccess) {
                      this.getPolicyClaimDetails();
                      this.clearDataFromCache = false;
                    }
                    
                }
                
              },
              error => {
                console.log("error");
                console.log(error);
              }
            )
  }

  isMoreThanOneImei(policies: Policy[]) {
    const imeis = [];
    if (policies.length == 1) {
      return false;
    }
    for(let pol of policies) {
      if (pol.imei && pol.imei.trim().length>0) {
        const imeiNumber = pol.imei.trim();
        const index  = imeis.indexOf(imeiNumber);
        if (index == -1) {
          imeis.push(imeiNumber);
        }
      }
    }
    console.log("morethan one ");
    console.log(imeis);

    return imeis.length > 1
  }

  getClaims() {
    if (!this.searchRequest.imei) {
      return;
    }
    this.searchRequest.policyId = []
    for (const pol of this.searchResult.policies) {

      this.searchRequest.policyId.push(pol._id);
    }

    this.searchSubscription = this.searchService.getClaimsByImei(this.searchRequest)
        .subscribe(
          response => {
            // console.log("claims");
            // console.log(response);
            if (response && response.length && response.length > 0) {
              this.searchResult.claims = response;
              this.claimResult = this.searchResult.claims;
              this.claimResultSuccess = true;
              if (this.policyResultSuccess && this.claimResultSuccess) {
                this.getPolicyClaimDetails();
                this.clearDataFromCache = false;
                
              }
              if (this.searchResult.claims &&  this.searchResult.claims.length > 0) {
                for (let claim of this.searchResult.claims) {
                  //this.getCommentsForClaim(claim);
                  this.getClaimCommentsFromSecondCRM(claim);
                }
                
                
                // console.log("******************");
                // console.log(this.claimResult);
              }
            }
            else {
              this.searchResult.claims = [];
              this.claimResult = [];
              this.claimResultSuccess = true;
              if (this.policyResultSuccess && this.claimResultSuccess) {
                this.getPolicyClaimDetails();
                this.clearDataFromCache = false;
                
              }
              
            }
            
            
          },
          error => {
            console.log("error");
            console.log(error);
          }
        )
  }

  getPolicyClaimDetails() {
    this.policyResultSuccess = false;
    this.claimResultSuccess = false;
    this.searchSubscription = this.searchService.getPolicyClaimDetails(this.searchResult.policies, this.searchResult.claims)
      .subscribe(
        response => {
          console.log("policclaimdetails");
          console.log(response);
          this.searchResult.policies = response.policies;
          this.searchResult.claims = response.claims;
          for (let pol of this.searchResult.policies) {
            this.getCommentsForPolicy(pol);
          }
          
        },
        error => {
          console.log("error");
          console.log(error);
        }
      )
  }

  
  getCustomer(customerId: string) {
    this.searchSubscription = this.searchService.getCustomerByCustomerId(customerId)
      .subscribe(
        response => {
          console.log("customer");
          console.log(response);
          this.searchResult.customerInfo = response;
          this.getUpdatedCustomerInfo();
          

        },
        error => {
          console.log("error");
          console.log(error)
        }
      )
  }

  

  getUpdatedCustomerInfo() {
    this.searchSubscription = this.searchService.getUpdatedCustomerInfo(this.searchResult.customerInfo, this.searchResult.policies)
      .subscribe(
        response => {
          console.log("customer");
          console.log(response);
          this.searchResult.customerInfo = response;

        },
        error => {
          console.log("error");
          console.log(error)
        }
      )
  }

  getCommentsForPolicy(policy: Policy) {
    this.searchSubscription = this.searchService.getComments(this.textToBeSearch, this.username, policy._id, "Policy")
        .subscribe(
          response => {
            console.log("policy comments");
            //console.log(response);
            policy.comments = response;
            console.log(policy.comments);
          },
          error => {
            console.log("error");
            console.log(error);
          }
        )
  }

  getTempCommnets(policy: Policy) {
    console.log("&&&&&&&&&&&&&");
    console.log(policy.comments);
  }

  getCommentsForClaim(claim: Claim) {
    this.searchSubscription = this.searchService.getComments(this.textToBeSearch, this.username, claim.id, "Claim")
        .subscribe(
          response => {
            console.log("claims");
            console.log(response);
            if (response) {
              if (claim.comments == null) {
                claim.comments = []
              }
              for(let commnet of response) {
                if (!commnet.createdDt && commnet.createdtime) {
                  commnet.createdDt = commnet.createdtime
                }
                if (!this.isCommentPresent(claim, commnet)) {
                  claim.comments.push(commnet);
                }
                
              }
            }
            
            
          },
          error => {
            console.log("error");
            console.log(error);
          }
        )
  }

  getClaimCommentsFromSecondCRM(claim: Claim) {
    this.searchSubscription = this.searchService.getClaimCommentsFromSecondCRM(claim.id)
        .subscribe(
          response => {
            console.log("claims from second crm");
            console.log(response);
            if (response) {
              if (!claim.comments) {
                claim.comments = []
              }
              for(let commnet of response) {
                if (!commnet.createdDt && commnet.createdtime) {
                  commnet.createdDt = commnet.createdtime
                }
                if (!this.isCommentPresent(claim, commnet)) {
                  claim.comments.push(commnet);
                }
                
              }
            }
            
          },
          error => {
            console.log("error");
            console.log(error);
          }
        )
  }

  isCommentPresent(claim: Claim, comment: Comments) {
    for(let com of claim.comments) {
      if (com.description== comment.description) {
        if (com.createdDt && comment.createdDt && com.createdDt == comment.createdDt) {
          return true;
        }
        if (com.createdtime && comment.createdtime && com.createdtime == comment.createdtime) {
          return true;
        }
      }
    }
    return false;
  }

  

  cognitoGetSession() {
    this.cognitoUtil.getSession((session) => {
      if (session) {
        this.session = session;
        session.getAccessToken().getJwtToken();
        session.getAccessToken().getExpiration();
        session.getIdToken().getJwtToken();
        session.getIdToken().getExpiration();
        session.getRefreshToken().getToken();
      }
    });
  }
  formatDate(date) {
    var dateOut = new Date(date);
    return dateOut;
  }
  cognitoValidateSession() {
    this.userService.isAuthenticated((isValiedSession, error) => {
      if (error) {
        this.userLoggedIn = false;
        console.log('HomeComponent: in cognitoValidateSession: ' + error);
        this.router.navigate(['/pg/login']);
        return;
      }
      this.userLoggedIn = isValiedSession;
      console.log('is valid session ');
      console.log(isValiedSession);
      if (!isValiedSession) {
        this.router.navigate(['/pg/login']);
      }
      else {
        this.user = this.userService.getuser();
        console.log('user present');
        console.log(this.user);
        this.username = this.user.username;
        
        console.log(this.username)
        this.virtualAgentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.virtualAgentUrl + "&loggedInUserName=" + this.username);
      }
    });
  }

  navigateToProfile() {
    this.router.navigate(['/pg/profile']);
  }

  onclickpolicytab(policy: Policy) {
    console.log("onclickpolicytab");
    this.claimResult = []
    for (let cl of this.searchResult.claims) {
      if (cl.imei == policy._id) {
        console.log("********************************************");
        this.claimResult.push(cl);
      }
    }

  }

  isTextSearchPhoneNumber() {
    return this.textToBeSearch && this.textToBeSearch.trim().length == 10 && this.isNumber(this.textToBeSearch);
  }

  isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

  getImeiNumber() {
    if (this.isTextSearchPhoneNumber() && this.searchResult.policies && this.searchResult.policies.length > 0) {
      return this.searchResult.policies[0].imei;
    }
    return this.textToBeSearch;
  }

  // search text
  searchText() {
    this.errorMessage = null;
    if (this.textToBeSearch) {
      if (this.isTextSearchPhoneNumber()) {
        this.searchRequest.phoneNumber = this.textToBeSearch;
        this.searchRequest.imei = null;
      }
      else{
        this.searchRequest.imei = this.textToBeSearch;
        this.searchRequest.phoneNumber = null;
      }
      this.searchRequest.clearCache = this.clearDataFromCache;

    }
    this.searchRequest.userName = this.username;
    this.getPolicies();
    //this.getClaims();

    if (this.interval != undefined) {
      this.clearTimer();
    }
    this.startTimer();
    this.data_not_found = false;
    this.fetchCRMError = false;
    this.spinner.show();
    setTimeout(() => {
        //this.getPolicyClaimDetails();        
    }, 30000);

    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 7000);


    // if (this.textToBeSearch) {
    //   this.searchSubscription = this.searchService.search(this.textToBeSearch)
    //     .subscribe(
    //       response => {
    //         if (!this.loadWorkbench) {
    //           if (!this.loadWorkbench) {
    //             setTimeout( () => {
    //               this.loadWorkbench = true;
    //               this.refreshChat();
    //             }, 10000);
                
    //           }
              
    //         }
    //         this.searchResult = response;
    //         if (this.searchResult.policies.length > 0) {
    //           this.onclickpolicytab(response.policies[0].salesorder_no);
    //           this.selectedClientName = this.searchResult.policies[0].clientName;
    //           this.selectedPolicyNo = this.searchResult.policies[0].salesorder_no;
    //           this.searchFl = true;
    //         }
    //         else {
    //           this.data_not_found = true;
    //         }
    //         this.spinner.hide();
    //         this.spinner.hide();
    //       },
    //       error => {
    //         console.log("error");
    //         this.spinner.hide();
    //         this.fetchCRMError = true;
    //       }
    //     )
    // }

  }


  // save comment text
  savePolicyComment(policy?: Policy) {
    if (policy) {
      this.commentArea = false;
      this.tempPolicyComment.parentId = policy._id;
      this.tempPolicyComment.type = "Policy";
      this.tempPolicyComment.userName = this.username;
      
      if ((this.tempPolicyComment.callType == null) || (this.tempPolicyComment.description ==null)){
        alert('Please enter all mandatory fields..!!');
        return;
      }
      if (this.tempPolicyComment.description !=null && this.tempPolicyComment.description.length==0){
        alert('Please enter all mandatory fields..!!');
        return;
      }
      if (this.tempPolicyComment.status == null){
        this.commentsFormstatus="Open";
      }
      if (this.tempPolicyComment.priority == null){
        this.commentsFormpriority ="Low";
      }
      this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
      console.log('--->')
      var d = new Date();
      console.log(d);
      let s = this.datePipe.transform(d, "MM-dd-yyyy HH:mm");
      this.searchSubscription = this.saveService.saveCommentFromUser(this.tempPolicyComment)
            .subscribe(
              response => {
                console.log("save comment from user " )
                console.log(response);
                if (policy != null) {
                  if (!policy.comments || policy.comments.length == 0) {
                    policy.comments = [];
                  }
                  policy.comments.push(response);
                }
              },
              error => {
                console.log("error");
                console.log(error);
              }
            )
    }

  }

  saveClaimComments(claim?: Claim) {
    if (claim) {
      console.log("saveClaim");
      console.log(claim)
      this.commentArea = false;
      console.log(this.tempClaimComment);
      this.tempClaimComment.parentId = claim.id;
      this.tempClaimComment.type = "Claim";
      this.tempClaimComment.userName = this.username;
      this.tempClaimComment.imei = this.textToBeSearch;
      
      if ((this.tempClaimComment.callType == null) || (this.tempClaimComment.description ==null)){
        alert('Please enter all mandatory fields..!!');
        return;
      }
      if (this.tempClaimComment.description !=null && this.tempClaimComment.description.length==0){
        alert('Please enter all mandatory fields..!!');
        return;
      }
      if (this.tempClaimComment.status == null){
        this.commentsFormstatus="Open";
      }
      if (this.tempClaimComment.priority == null){
        this.commentsFormpriority ="Low";
      }
      this.spinner.show();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 3000);
      console.log('--->')
      var d = new Date();
      console.log(d);
      let s = this.datePipe.transform(d, "MM-dd-yyyy HH:mm");
      const comment: Comments = JSON.parse(JSON.stringify(this.tempClaimComment));
      comment.description = this.getClaimDescription(comment);
      comment.createdDt = new Date().toString();
      claim.comments.push(comment);
      this.searchSubscription = this.saveService.saveCommentFromUser(this.tempClaimComment)
            .subscribe(
              response => {
                console.log("save comment from user " )
                console.log(response);
                if (claim != null) {
                  if (!claim.comments || claim.comments.length == 0) {
                    claim.comments = [];
                  }
                  //claim.comments.push(response);
                  console.log(claim.comments);
                  this.tempClaimComment = null;
                }
              },
              error => {
                console.log("error");
                console.log(error);
              }
            )
    }

  }

  getClaimDescription(userComment: Comments) {
    var description = ";"
    if (userComment && userComment.description ) {
      if (userComment.callType != null)
            description = description + "Call Type: " + userComment.callType + ", "
        if (userComment.description != null)
            description = description + "Desc: " + userComment.description + ", "
        if (userComment.assignedTo)
            description = description + "Assigned to: " + userComment.assignedTo + ", "
        if (userComment.status != null)
            description = description + "Status: " + userComment.status + ", "
        if (userComment.priority != null)
            description = description + "Priority: " + userComment.priority + ", "
    }
    return description;
  }


  // save exception text
  saveException() {
    
      //this.tempExceptionalCommnet.imei = this.getImeiNumber();
      this.tempExceptionalCommnet.userName = this.username;
      if ((this.tempExceptionalCommnet.imeiphonenumber == null) ||(this.tempExceptionalCommnet.callType == null) || (this.tempExceptionalCommnet.description ==null)){
        alert('Please enter all mandatory fields..!!');
        return;
      }
      if (this.tempExceptionalCommnet.description !=null && this.tempExceptionalCommnet.description.length==0 && this.tempExceptionalCommnet.imeiphonenumber.length<10){
        alert('Please enter all mandatory fields..!!');
        return;
      }
      if (this.tempExceptionalCommnet.status == null){
        this.tempExceptionalCommnet.status = "Open";
      }
      if (this.tempExceptionalCommnet.priority == null){
        this.tempExceptionalCommnet.priority ="Low";
      }
      this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
      console.log('--->')
      var d = new Date();
      console.log(d);
      let s = this.datePipe.transform(d, "MM-dd-yyyy HH:mm");
      this.searchSubscription = this.saveService.saveCommentFromUser(this.tempExceptionalCommnet)
        .subscribe(
          response => {
            this.clearForm();
            this.tempExceptionalCommnet = null;
            alert("Data saved");
          },
          error => {

          }
        )
      // this.searchSubscription = this.saveService.saveComment(this.commentsFormdescription, this.commentsmobileimei, this.commentsFormcallType, this.commentsFormassignTo, this.commentsFormstatus, this.commentsFormpriority, "Exception", s)
      //   //description: string,related_to:String,callType:String,assignedTo:String,status:String,priority:String
      //   .subscribe(
      //     response => {
      //       console.log("Comments Saved..!!");
      //       //console.log(response);
      //       this.saveResponse = response;  
      //       this.clearForm();
      //       alert('Data Saved.');         
      //     },
      //     error => {
      //       console.log("error");
      //     }
      //   )
    

  }
  // save comment text
  saveComment(related_to: string) {
    if (related_to) {
      this.commentArea = false;
      
      if ((this.commentsFormcallType == null) || (this.commentsFormdescription ==null)){
        alert('Please enter all mandatory fields..!!');
        return;
      }
      if (this.commentsFormdescription !=null && this.commentsFormdescription.length==0){
        alert('Please enter all mandatory fields..!!');
        return;
      }
      if (this.commentsFormstatus == null){
        this.commentsFormstatus="Open";
      }
      if (this.commentsFormpriority == null){
        this.commentsFormpriority ="Low";
      }
      this.spinner.show();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 3000);
      console.log('--->')
      var d = new Date();
      console.log(d);
      let s = this.datePipe.transform(d, "MM-dd-yyyy HH:mm");
      if (this.commentsFormassignTo !=null){
        this.commentsFormassignTo="Assigned To : "+this.commentsFormassignTo;
      }
      this.searchSubscription = this.saveService.saveComment("Description : "+this.commentsFormdescription, related_to, "Call Type : "+this.commentsFormcallType, this.commentsFormassignTo, "Status : "+this.commentsFormstatus, "Priority : "+this.commentsFormpriority, "Claim", s)
        //description: string,related_to:String,callType:String,assignedTo:String,status:String,priority:String
        .subscribe(
          response => {
            console.log("Comments Saved..!!");
            //console.log(response);
            this.saveResponse = response;

            for (let claim of this.searchResult.claims) {
              if (claim.id == related_to) {
                let com = new Comments();
                com.commentcontent = this.claimComment;

                console.log(s);
                com.createdtime = s;
                com.callType = this.commentsFormcallType;
                com.description = this.commentsFormdescription;
                com.priority = this.commentsFormpriority;
                com.status = this.commentsFormstatus;
                com.assignedTo = this.commentsFormassignTo;
                com.commentcontent=com.description+com.callType+com.status+com.priority;
                claim.comments.push(com);
              }
              this.clearForm();
            }
          },
          error => {
            console.log("error");
          }
        )
    }

  }
  clearForm()
  {
      this.commentsFormcallType=null;
      this.commentsFormdescription=null;
      this.commentsFormassignTo=null;
      this.commentsmobileimei=null;
      this.commentsFormpriority="Low";
      this.commentsFormstatus="Open";
  }
  commentArea: boolean = false;
  showCommentArea() {
    console.log('show commment area ');
    this.commentArea = true;
    this.claimComment = '';
  }


  // get chat messages 
  getChat(episodeIds: string[]) {
    for (let episodeId of episodeIds) {
      this.conversationSubscriptions = this.conversationService.getChatMessages(episodeId)
        .subscribe(
          response => {
            console.log("chat messages");
            console.log(response);
            if (this.searchResult.activity == null) {
              this.searchResult.activity = new Activity();
            }
            this.searchResult.activity.episodeChats.push(new EpisodeChat(episodeId, response));
            console.log(this.searchResult.activity);
          },
          error => {

          }
        )
    }

  }

  onPolicyClick(policyIndex: number, policy: Policy) {
    console.log("onPolicyClick");
    console.log(policyIndex);
    this.policyIndex = policyIndex;
    this.onclickpolicytab(policy);
    this.selectedClientName = this.searchResult.policies[policyIndex].clientName;
    this.selectedPolicyNo = this.searchResult.policies[policyIndex].policyId;
  }

  getStringDateFromMillis(millis: number) {
    return moment(new Date(millis)).format("HH:mm");
  }

  isPolicyStatusOtherThanCurrent(policyStatus: string) {
    if (policyStatus == null || policyStatus.trim().length == 0) {
      return false;
    }
    if (policyStatus.trim().toUpperCase() != "CURRENT") {
      return true;
    }
    return false;
  }

  isDateLessThanToday(dateToBecompare: number) {
    if (dateToBecompare != null && dateToBecompare != 0) {
      const dateObjToBecompare = new Date(dateToBecompare);
      const todayDate = new Date();
      if (dateObjToBecompare <= todayDate) {
        return true;
      }
      return false;
    }
  }

  isDateGreaterThanToday(dateToBecompare: number) {
    if (dateToBecompare != null && dateToBecompare != 0) {
      const dateObjToBecompare = new Date(dateToBecompare);
      const todayDate = new Date();
      if (dateObjToBecompare > todayDate) {
        return true;
      }
      return false;
    }
  }

  timeLeft: number = 0;
  minutes: number = 0;
  secondadd: string = "0";
  minuteadd: string = "0";
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft < 60) {
        this.timeLeft++;
        if (this.timeLeft < 10) {
          this.secondadd = "0";
        }
        else {
          this.secondadd = "";
        }
      } else {
        this.minutes++;
        this.timeLeft = 0;
        this.secondadd = "0";
        if (this.minutes < 10) {
          this.minuteadd = "0";
        }
        else {
          this.minuteadd = ""
        }
      }
    }, 1000)
  }

  clearTimer() {
    clearInterval(this.interval);
    this.minutes = 0;
    this.timeLeft = 0;
    this.secondadd = "0";
    this.minuteadd = "0";
  }


  lessThanThirtyDays(dateToBecompare: number) {
    if (dateToBecompare != null && dateToBecompare != 0) {
      const dateObjToBecompareObj = new Date(dateToBecompare);
      dateObjToBecompareObj.setDate(dateObjToBecompareObj.getDate() - 30);
      const todayDate = new Date();
      if (dateObjToBecompareObj <= todayDate) {
        return true;
      }
      return false;
    }
  }

  newChat() {
    this.router.navigate(['/pg/home']);
    if (this.interval != undefined) {
      this.clearTimer();
    }
    this.selectedClientName='';
    this.selectedPolicyNo='';
    this.searchResult=new SearchResponse;
    this.startTimer();
    const event = new EventData();
    event.eventId = "CONVERSATION_RESTART";
    event.messageText = "start";
    event.displayText = "start";
    event.formResult = {};
    this.textToBeSearch = null;
    this.searchResult = new SearchResponse();
    this.searchFl = false;
    if (this.textToBeSearch) {
      event.contextData = { imei: null };
    }
    this.raiseEventToIframe(event);

  }

  refreshChat() {
    console.log("refresh chat");
    if (this.interval != undefined) {
      this.clearTimer();
    }
    this.startTimer();
    const event = new EventData();
    event.eventId = "CONVERSATION_RESTART";
    event.messageText = "start";
    event.displayText = "start";
    event.formResult = {};
    if (this.textToBeSearch) {
      event.contextData = { imei: this.textToBeSearch }
    }
    this.raiseEventToIframe(event);
    

  }

  raiseEventToIframe(eventData: EventData) {
    this.autoBotRefId.nativeElement.contentWindow.postMessage(
      eventData,
      "*"
    )

  }
  isException: boolean=false;
  loadPage: boolean=true;
  exceptionLog()
  {
    console.log('inside exception log');
    this.isException=true;
    this.loadPage=false;
  }
  open(content) {
    console.log('inside open modal');
    this.tempExceptionalCommnet = new Comments();
    this.tempExceptionalCommnet.type = "Exception"
    this.tempExceptionalCommnet.imei = this.getImeiNumber();
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    console.log('inside dismiss');
    if (reason === ModalDismissReasons.ESC) {
      console.log('esc');
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      console.log('click');
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getCustomerName(customer: CustomerInfo) {
    var name = ""
    if(customer.firstName) {
      name = customer.firstName + " "
    }
    if (customer.lastName) {
      name = name + customer.lastName;
    }
    return name;
  }

  getBalanceConver(pollicy: Policy) {
    var totalBenefit = 0;
    if (typeof pollicy.totalBenefit == "string" && pollicy.totalBenefit != null) {
      totalBenefit = parseFloat(pollicy.totalBenefit)
    }
    var repairCost = 0;
    if (pollicy.repairCost != null && typeof pollicy.repairCost == "string") {
      repairCost = parseFloat(pollicy.repairCost);
    }
    if (totalBenefit > 0) {
      return (totalBenefit - repairCost) / (totalBenefit * 1.0)
    }
    return 0;
    
  }

  getcustomerMobileNumber() {
    if (this.searchResult && this.searchResult.customerInfo && this.searchResult.customerInfo.phoneNumber) {
      return this.searchResult.customerInfo.phoneNumber;
    }
    if (this.searchResult.policies) {
      for (let pol of this.searchResult.policies) {
        if (pol.mobileNo) {
          return pol.mobileNo;
        }
      }
    }
    return null;
  }

  clearCache() {
    this.clearDataFromCache = true;
    this.searchText();
  }
}

