import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
var SearchService = /** @class */ (function () {
    function SearchService(httpClient) {
        this.httpClient = httpClient;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
    SearchService.prototype.search = function (searchText) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.searchApi);
        var body = { "imei-number": searchText, "companyContext": { "companyId": environment.companyId } };
        this.httpClient.post(url, body, {
            headers: this.httpHeaders,
            observe: 'response'
        }).subscribe(function (response) {
            if (response && response.body) {
                subject.next(response.body);
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    SearchService.prototype.getPoliciesByImei = function (searchRequest) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.policies);
        searchRequest.companyContext = { "companyId": environment.companyId };
        //const body = { "imei" : imei, "companyContext": { "companyId": environment.companyId } };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, searchRequest, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                if (response.body.success) {
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    SearchService.prototype.getClaimsByImei = function (searchRequest) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.claims);
        searchRequest.companyContext = { "companyId": environment.companyId };
        //const body = { "imei" : imei, "companyContext": { "companyId": environment.companyId } };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, searchRequest, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                if (response.body.success) {
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    SearchService.prototype.getPolicyClaimDetails = function (policies, claims) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.policyclaimdetails);
        var body = { "policies": policies, "claims": claims, "companyContext": { "companyId": environment.companyId } };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                if (response.body.success) {
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    SearchService.prototype.getCustomerByCustomerId = function (customerId) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.customerbyid);
        var body = { "customerId": customerId, "companyContext": { "companyId": environment.companyId } };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                if (response.body.success) {
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    SearchService.prototype.getUpdatedCustomerInfo = function (customerInfo, policies) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.policyclaimdetails);
        var body = { "customerInfo": customerInfo, "policies": policies, "companyContext": { "companyId": environment.companyId }, "getUpdateCustomerInfo": true };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                if (response.body.success) {
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    SearchService.prototype.getComments = function (imei, userName, parentId, type) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.comments);
        var body = {
            key: {
                imei: imei,
                parentId: parentId,
                type: type
            },
            operation: "GET_ALL",
            companyContext: { companyId: environment.companyId }
        };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                if (response.body.success) {
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    SearchService.prototype.getClaimCommentsFromSecondCRM = function (claimId) {
        var subject = new Subject();
        var url = "" + (environment.apiurl + environment.comments);
        var body = {
            claimId: claimId,
            operation: "GET_CLAIM_COMMNETS",
            companyContext: { companyId: environment.companyId }
        };
        var httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                if (response.body.success) {
                    console.log("claim comments service");
                    console.log(response.body.result);
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    SearchService.prototype.getEcertByImei = function (imei, smsFlag, policyId) {
        var subject = new Subject();
        var url = "" + (environment.awsurl + environment.policiesForEcertApi);
        var body = {
            companyContext: { companyId: environment.ecertCompanyId },
            imei: imei,
            noSendEcert: smsFlag,
            policyId: policyId
        };
        var httpHeaders = new HttpHeaders({
            'X-Api-Key': environment.x_Api_Key
        });
        this.httpClient.post(url, body, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe(function (response) {
            if (response && response.body) {
                if (response.body.success) {
                    console.log("claim ecert download ");
                    subject.next(response.body.result);
                }
            }
        }, function (err) {
            console.log(err);
        });
        return subject.asObservable();
    };
    return SearchService;
}());
export { SearchService };
