import { OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { Subscription } from 'rxjs/Subscription';
import { UserLoginService } from '../../services/aws/user-login.service';
import { CognitoUtil } from "../../services/aws/cognito.service";
import { Subscription } from "rxjs";
import * as moment from 'moment/moment';
import { SearchResponse } from '../../models/searchresponse.model';
import { Activity, EpisodeChat } from '../../models/activity.model';
import { EventData } from '../../models/event.model';
import { SearchService } from '../../services/search.service';
import { SaveServices } from '../../services/save.services';
import { ConversationService } from '../../services/conversation.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Comments } from '../../models/comments.model';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SearchRequest } from '../../models/searchrequest.model';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(document, router, route, userService, cognitoUtil, searchService, saveService, conversationService, sanitizer, spinner, datePipe, modalService) {
        var _this = this;
        this.document = document;
        this.router = router;
        this.route = route;
        this.userService = userService;
        this.cognitoUtil = cognitoUtil;
        this.searchService = searchService;
        this.saveService = saveService;
        this.conversationService = conversationService;
        this.sanitizer = sanitizer;
        this.spinner = spinner;
        this.datePipe = datePipe;
        this.modalService = modalService;
        this.selectedClientName = '';
        this.selectedPolicyNo = '';
        this.searchFl = false;
        this.enteredIMEINo = '';
        this.loadWorkbench = false;
        this.claimResult = [];
        this.policyIndex = 0;
        this.commentsFormdescription = null;
        this.commentsFormassignTo = null;
        this.commentsFormpriority = 'Low';
        this.commentsFormstatus = 'Open';
        this.commentsFormcallType = null;
        this.commentsmobileimei = null;
        this.virtualAgentUrl = null;
        this.fetchCRMError = true;
        this.sort = 0;
        this.policyResultSuccess = false;
        this.claimResultSuccess = false;
        this.clearDataFromCache = false;
        this.searchRequest = null;
        this.errorMessage = null;
        this.home = {
            email: '',
            name: '',
            mobile: '',
            alternatemobile: '',
            imei: '',
            policy_imei: '',
            policyid: '',
            bill_add: '',
            policystatus: '',
            make: '',
            model: '',
            mobilecost: '',
            excessfee: '',
            policystartdate: '',
            policyenddate: '',
            mpd: '',
            pp: '',
            clientname: '',
            protectiontype: '',
            policytype: '',
            programtype: '',
            programname: '',
            underwritername: '',
            policy_imei2: '',
            policyid2: '',
            bill_add2: '',
            policystatus2: '',
            make2: '',
            model2: '',
            mobilecost2: '',
            excessfee2: '',
            policystartdate2: '',
            policyenddate2: '',
            mpd2: '',
            pp2: '',
            clientname2: '',
            protectiontype2: '',
            policytype2: '',
            programtype2: '',
            programname2: '',
            underwritername2: ''
        };
        // Activity information starts here
        this.active = 0;
        this.commentArea = false;
        this.timeLeft = 0;
        this.minutes = 0;
        this.secondadd = "0";
        this.minuteadd = "0";
        this.isException = false;
        this.loadPage = true;
        this.sort = 0;
        this.tempPolicyComment = null;
        this.tempClaimComment = null;
        this.tempExceptionalCommnet = null;
        this.searchResult = new SearchResponse();
        this.searchRequest = new SearchRequest();
        window.addEventListener('message', function (event) {
            console.log("amtrust listen event");
            //console.log(event.data);
            if (event && event.data) {
                var eventData = event.data;
                if (eventData && eventData.eventId && eventData.eventId == "IMEI") {
                    _this.textToBeSearch = eventData.messageText;
                    _this.searchText();
                }
                if (eventData && eventData.eventId && eventData.eventId == "IMEI_MULTIPLE") {
                    if (eventData.formResult && eventData.formResult.selectedImei) {
                        _this.textToBeSearch = eventData.formResult.selectedImei;
                        _this.searchText();
                    }
                }
            }
        });
    }
    HomeComponent.prototype.ngOnInit = function () {
        // this.textToBeSearch = "9819430048";
        // this.searchText();
        this.cognitoValidateSession();
        this.cti_imei = this.route.snapshot.paramMap.get("imei");
        if (this.cti_imei != null) {
            this.textToBeSearch = this.cti_imei;
            this.searchText();
            this.loadWorkbench = true;
            this.errorMessage = null;
        }
        else {
            this.loadWorkbench = true;
        }
        this.innerHeight = window.screen.height;
        console.log("IMEI Received in the URL:" + this.cti_imei);
    };
    HomeComponent.prototype.activity = function () {
        this.active = 1;
        this.tempPolicyComment = new Comments();
        this.tempPolicyComment.userName = this.username;
        this.tempPolicyComment.imei = this.getImeiNumber();
    };
    HomeComponent.prototype.ecertDownload = function (smsFlag) {
        var _this = this;
        var linkChk;
        this.policyResultSuccess = false;
        this.claimResultSuccess = false;
        console.log("---policyId for ecert download-" + this.searchResult.policies[0].policyId);
        this.spinner.show();
        this.searchSubscription = this.searchService.getEcertByImei(this.getImeiNumber(), smsFlag, this.searchResult.policies[0].policyId)
            .subscribe(function (response) {
            if (response && response.length && response.length > 0) {
                linkChk = response[0].pdfLink;
                console.log(JSON.stringify(response));
                console.log("----linkChk---" + linkChk);
                _this.spinner.hide();
                if (linkChk != null) {
                    window.open(linkChk);
                }
                else {
                    alert("premium band not config!");
                }
            }
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    HomeComponent.prototype.onAddClaimActivty = function () {
        this.tempClaimComment = new Comments();
        this.tempClaimComment.userName = this.username;
        this.tempClaimComment.imei = this.getImeiNumber();
    };
    HomeComponent.prototype.onAddPolicyActivty = function () {
        this.tempPolicyComment = new Comments();
        this.tempPolicyComment.userName = this.username;
        this.tempPolicyComment.imei = this.getImeiNumber();
    };
    HomeComponent.prototype.sorted = function () {
        if (this.sort == 0)
            this.sort = 1;
        else
            this.sort = 0;
    };
    // Activity information ends here
    HomeComponent.prototype.doAction = function (action) {
        console.log(action);
    };
    HomeComponent.prototype.cognitoSignIn = function () {
        this.router.navigate(['/pg/login']);
    };
    HomeComponent.prototype.refresh = function () {
        window.location.reload();
    };
    HomeComponent.prototype.cognitoSignUp = function () {
        this.router.navigate(['/pg/register']);
    };
    HomeComponent.prototype.cognitoChangePwd = function () {
        this.router.navigate(['/pg/login']);
    };
    HomeComponent.prototype.cognitoSignOut = function () {
        if (this.userLoggedIn) {
            this.userService.logout();
            this.cognitoValidateSession();
        }
        this.router.navigate(['/pg/login']);
    };
    HomeComponent.prototype.getPolicies = function () {
        var _this = this;
        this.searchSubscription = this.searchService.getPoliciesByImei(this.searchRequest)
            .subscribe(function (response) {
            // console.log("policies");
            // console.log(response);
            _this.spinner.hide();
            if (!response && response.length == 0) {
                return;
            }
            console.log("*********************");
            console.log(_this.searchRequest);
            if (_this.searchRequest.phoneNumber != null) {
                if (_this.isMoreThanOneImei(response)) {
                    console.log(_this.searchRequest.phoneNumber);
                    console.log(_this.isMoreThanOneImei(response));
                    _this.errorMessage = "There are more than one imeis for provided phone number";
                    return;
                }
                else {
                    _this.errorMessage = null;
                    _this.searchRequest.imei = response[0].imei.trim();
                }
            }
            _this.searchResult.policies = response;
            _this.getClaims();
            _this.loadWorkbench = true;
            if (_this.searchResult.policies.length > 0) {
                _this.onclickpolicytab(_this.searchResult.policies[0]);
                _this.selectedClientName = _this.searchResult.policies[0].clientName;
                _this.selectedPolicyNo = _this.searchResult.policies[0].policyId;
                _this.searchFl = true;
                if (!_this.searchResult.customerInfo || _this.searchResult.customerInfo == null || !_this.searchResult.customerInfo._id) {
                    _this.getCustomer(_this.searchResult.policies[0].customerId);
                }
                _this.policyResultSuccess = true;
                if (_this.policyResultSuccess && _this.claimResultSuccess) {
                    _this.getPolicyClaimDetails();
                    _this.clearDataFromCache = false;
                }
            }
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    HomeComponent.prototype.isMoreThanOneImei = function (policies) {
        var imeis = [];
        if (policies.length == 1) {
            return false;
        }
        for (var _i = 0, policies_1 = policies; _i < policies_1.length; _i++) {
            var pol = policies_1[_i];
            if (pol.imei && pol.imei.trim().length > 0) {
                var imeiNumber = pol.imei.trim();
                var index = imeis.indexOf(imeiNumber);
                if (index == -1) {
                    imeis.push(imeiNumber);
                }
            }
        }
        console.log("morethan one ");
        console.log(imeis);
        return imeis.length > 1;
    };
    HomeComponent.prototype.getClaims = function () {
        var _this = this;
        if (!this.searchRequest.imei) {
            return;
        }
        this.searchRequest.policyId = [];
        for (var _i = 0, _a = this.searchResult.policies; _i < _a.length; _i++) {
            var pol = _a[_i];
            this.searchRequest.policyId.push(pol._id);
        }
        this.searchSubscription = this.searchService.getClaimsByImei(this.searchRequest)
            .subscribe(function (response) {
            // console.log("claims");
            // console.log(response);
            if (response && response.length && response.length > 0) {
                _this.searchResult.claims = response;
                _this.claimResult = _this.searchResult.claims;
                _this.claimResultSuccess = true;
                if (_this.policyResultSuccess && _this.claimResultSuccess) {
                    _this.getPolicyClaimDetails();
                    _this.clearDataFromCache = false;
                }
                if (_this.searchResult.claims && _this.searchResult.claims.length > 0) {
                    for (var _i = 0, _a = _this.searchResult.claims; _i < _a.length; _i++) {
                        var claim = _a[_i];
                        //this.getCommentsForClaim(claim);
                        _this.getClaimCommentsFromSecondCRM(claim);
                    }
                    // console.log("******************");
                    // console.log(this.claimResult);
                }
            }
            else {
                _this.searchResult.claims = [];
                _this.claimResult = [];
                _this.claimResultSuccess = true;
                if (_this.policyResultSuccess && _this.claimResultSuccess) {
                    _this.getPolicyClaimDetails();
                    _this.clearDataFromCache = false;
                }
            }
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    HomeComponent.prototype.getPolicyClaimDetails = function () {
        var _this = this;
        this.policyResultSuccess = false;
        this.claimResultSuccess = false;
        this.searchSubscription = this.searchService.getPolicyClaimDetails(this.searchResult.policies, this.searchResult.claims)
            .subscribe(function (response) {
            console.log("policclaimdetails");
            console.log(response);
            _this.searchResult.policies = response.policies;
            _this.searchResult.claims = response.claims;
            for (var _i = 0, _a = _this.searchResult.policies; _i < _a.length; _i++) {
                var pol = _a[_i];
                _this.getCommentsForPolicy(pol);
            }
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    HomeComponent.prototype.getCustomer = function (customerId) {
        var _this = this;
        this.searchSubscription = this.searchService.getCustomerByCustomerId(customerId)
            .subscribe(function (response) {
            console.log("customer");
            console.log(response);
            _this.searchResult.customerInfo = response;
            _this.getUpdatedCustomerInfo();
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    HomeComponent.prototype.getUpdatedCustomerInfo = function () {
        var _this = this;
        this.searchSubscription = this.searchService.getUpdatedCustomerInfo(this.searchResult.customerInfo, this.searchResult.policies)
            .subscribe(function (response) {
            console.log("customer");
            console.log(response);
            _this.searchResult.customerInfo = response;
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    HomeComponent.prototype.getCommentsForPolicy = function (policy) {
        this.searchSubscription = this.searchService.getComments(this.textToBeSearch, this.username, policy._id, "Policy")
            .subscribe(function (response) {
            console.log("policy comments");
            //console.log(response);
            policy.comments = response;
            console.log(policy.comments);
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    HomeComponent.prototype.getTempCommnets = function (policy) {
        console.log("&&&&&&&&&&&&&");
        console.log(policy.comments);
    };
    HomeComponent.prototype.getCommentsForClaim = function (claim) {
        var _this = this;
        this.searchSubscription = this.searchService.getComments(this.textToBeSearch, this.username, claim.id, "Claim")
            .subscribe(function (response) {
            console.log("claims");
            console.log(response);
            if (response) {
                if (claim.comments == null) {
                    claim.comments = [];
                }
                for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
                    var commnet = response_1[_i];
                    if (!commnet.createdDt && commnet.createdtime) {
                        commnet.createdDt = commnet.createdtime;
                    }
                    if (!_this.isCommentPresent(claim, commnet)) {
                        claim.comments.push(commnet);
                    }
                }
            }
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    HomeComponent.prototype.getClaimCommentsFromSecondCRM = function (claim) {
        var _this = this;
        this.searchSubscription = this.searchService.getClaimCommentsFromSecondCRM(claim.id)
            .subscribe(function (response) {
            console.log("claims from second crm");
            console.log(response);
            if (response) {
                if (!claim.comments) {
                    claim.comments = [];
                }
                for (var _i = 0, response_2 = response; _i < response_2.length; _i++) {
                    var commnet = response_2[_i];
                    if (!commnet.createdDt && commnet.createdtime) {
                        commnet.createdDt = commnet.createdtime;
                    }
                    if (!_this.isCommentPresent(claim, commnet)) {
                        claim.comments.push(commnet);
                    }
                }
            }
        }, function (error) {
            console.log("error");
            console.log(error);
        });
    };
    HomeComponent.prototype.isCommentPresent = function (claim, comment) {
        for (var _i = 0, _a = claim.comments; _i < _a.length; _i++) {
            var com = _a[_i];
            if (com.description == comment.description) {
                if (com.createdDt && comment.createdDt && com.createdDt == comment.createdDt) {
                    return true;
                }
                if (com.createdtime && comment.createdtime && com.createdtime == comment.createdtime) {
                    return true;
                }
            }
        }
        return false;
    };
    HomeComponent.prototype.cognitoGetSession = function () {
        var _this = this;
        this.cognitoUtil.getSession(function (session) {
            if (session) {
                _this.session = session;
                session.getAccessToken().getJwtToken();
                session.getAccessToken().getExpiration();
                session.getIdToken().getJwtToken();
                session.getIdToken().getExpiration();
                session.getRefreshToken().getToken();
            }
        });
    };
    HomeComponent.prototype.formatDate = function (date) {
        var dateOut = new Date(date);
        return dateOut;
    };
    HomeComponent.prototype.cognitoValidateSession = function () {
        var _this = this;
        this.userService.isAuthenticated(function (isValiedSession, error) {
            if (error) {
                _this.userLoggedIn = false;
                console.log('HomeComponent: in cognitoValidateSession: ' + error);
                _this.router.navigate(['/pg/login']);
                return;
            }
            _this.userLoggedIn = isValiedSession;
            console.log('is valid session ');
            console.log(isValiedSession);
            if (!isValiedSession) {
                _this.router.navigate(['/pg/login']);
            }
            else {
                _this.user = _this.userService.getuser();
                console.log('user present');
                console.log(_this.user);
                _this.username = _this.user.username;
                console.log(_this.username);
                _this.virtualAgentUrl = _this.sanitizer.bypassSecurityTrustResourceUrl(environment.virtualAgentUrl + "&loggedInUserName=" + _this.username);
            }
        });
    };
    HomeComponent.prototype.navigateToProfile = function () {
        this.router.navigate(['/pg/profile']);
    };
    HomeComponent.prototype.onclickpolicytab = function (policy) {
        console.log("onclickpolicytab");
        this.claimResult = [];
        for (var _i = 0, _a = this.searchResult.claims; _i < _a.length; _i++) {
            var cl = _a[_i];
            if (cl.imei == policy._id) {
                console.log("********************************************");
                this.claimResult.push(cl);
            }
        }
    };
    HomeComponent.prototype.isTextSearchPhoneNumber = function () {
        return this.textToBeSearch && this.textToBeSearch.trim().length == 10 && this.isNumber(this.textToBeSearch);
    };
    HomeComponent.prototype.isNumber = function (n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0); };
    HomeComponent.prototype.getImeiNumber = function () {
        if (this.isTextSearchPhoneNumber() && this.searchResult.policies && this.searchResult.policies.length > 0) {
            return this.searchResult.policies[0].imei;
        }
        return this.textToBeSearch;
    };
    // search text
    HomeComponent.prototype.searchText = function () {
        this.errorMessage = null;
        if (this.textToBeSearch) {
            if (this.isTextSearchPhoneNumber()) {
                this.searchRequest.phoneNumber = this.textToBeSearch;
                this.searchRequest.imei = null;
            }
            else {
                this.searchRequest.imei = this.textToBeSearch;
                this.searchRequest.phoneNumber = null;
            }
            this.searchRequest.clearCache = this.clearDataFromCache;
        }
        this.searchRequest.userName = this.username;
        this.getPolicies();
        //this.getClaims();
        if (this.interval != undefined) {
            this.clearTimer();
        }
        this.startTimer();
        this.data_not_found = false;
        this.fetchCRMError = false;
        this.spinner.show();
        setTimeout(function () {
            //this.getPolicyClaimDetails();        
        }, 30000);
        // setTimeout(() => {
        //   /** spinner ends after 5 seconds */
        //   this.spinner.hide();
        // }, 7000);
        // if (this.textToBeSearch) {
        //   this.searchSubscription = this.searchService.search(this.textToBeSearch)
        //     .subscribe(
        //       response => {
        //         if (!this.loadWorkbench) {
        //           if (!this.loadWorkbench) {
        //             setTimeout( () => {
        //               this.loadWorkbench = true;
        //               this.refreshChat();
        //             }, 10000);
        //           }
        //         }
        //         this.searchResult = response;
        //         if (this.searchResult.policies.length > 0) {
        //           this.onclickpolicytab(response.policies[0].salesorder_no);
        //           this.selectedClientName = this.searchResult.policies[0].clientName;
        //           this.selectedPolicyNo = this.searchResult.policies[0].salesorder_no;
        //           this.searchFl = true;
        //         }
        //         else {
        //           this.data_not_found = true;
        //         }
        //         this.spinner.hide();
        //         this.spinner.hide();
        //       },
        //       error => {
        //         console.log("error");
        //         this.spinner.hide();
        //         this.fetchCRMError = true;
        //       }
        //     )
        // }
    };
    // save comment text
    HomeComponent.prototype.savePolicyComment = function (policy) {
        var _this = this;
        if (policy) {
            this.commentArea = false;
            this.tempPolicyComment.parentId = policy._id;
            this.tempPolicyComment.type = "Policy";
            this.tempPolicyComment.userName = this.username;
            if ((this.tempPolicyComment.callType == null) || (this.tempPolicyComment.description == null)) {
                alert('Please enter all mandatory fields..!!');
                return;
            }
            if (this.tempPolicyComment.description != null && this.tempPolicyComment.description.length == 0) {
                alert('Please enter all mandatory fields..!!');
                return;
            }
            if (this.tempPolicyComment.status == null) {
                this.commentsFormstatus = "Open";
            }
            if (this.tempPolicyComment.priority == null) {
                this.commentsFormpriority = "Low";
            }
            this.spinner.show();
            setTimeout(function () {
                /** spinner ends after 5 seconds */
                _this.spinner.hide();
            }, 3000);
            console.log('--->');
            var d = new Date();
            console.log(d);
            var s = this.datePipe.transform(d, "MM-dd-yyyy HH:mm");
            this.searchSubscription = this.saveService.saveCommentFromUser(this.tempPolicyComment)
                .subscribe(function (response) {
                console.log("save comment from user ");
                console.log(response);
                if (policy != null) {
                    if (!policy.comments || policy.comments.length == 0) {
                        policy.comments = [];
                    }
                    policy.comments.push(response);
                }
            }, function (error) {
                console.log("error");
                console.log(error);
            });
        }
    };
    HomeComponent.prototype.saveClaimComments = function (claim) {
        var _this = this;
        if (claim) {
            console.log("saveClaim");
            console.log(claim);
            this.commentArea = false;
            console.log(this.tempClaimComment);
            this.tempClaimComment.parentId = claim.id;
            this.tempClaimComment.type = "Claim";
            this.tempClaimComment.userName = this.username;
            this.tempClaimComment.imei = this.textToBeSearch;
            if ((this.tempClaimComment.callType == null) || (this.tempClaimComment.description == null)) {
                alert('Please enter all mandatory fields..!!');
                return;
            }
            if (this.tempClaimComment.description != null && this.tempClaimComment.description.length == 0) {
                alert('Please enter all mandatory fields..!!');
                return;
            }
            if (this.tempClaimComment.status == null) {
                this.commentsFormstatus = "Open";
            }
            if (this.tempClaimComment.priority == null) {
                this.commentsFormpriority = "Low";
            }
            this.spinner.show();
            setTimeout(function () {
                /** spinner ends after 5 seconds */
                _this.spinner.hide();
            }, 3000);
            console.log('--->');
            var d = new Date();
            console.log(d);
            var s = this.datePipe.transform(d, "MM-dd-yyyy HH:mm");
            var comment = JSON.parse(JSON.stringify(this.tempClaimComment));
            comment.description = this.getClaimDescription(comment);
            comment.createdDt = new Date().toString();
            claim.comments.push(comment);
            this.searchSubscription = this.saveService.saveCommentFromUser(this.tempClaimComment)
                .subscribe(function (response) {
                console.log("save comment from user ");
                console.log(response);
                if (claim != null) {
                    if (!claim.comments || claim.comments.length == 0) {
                        claim.comments = [];
                    }
                    //claim.comments.push(response);
                    console.log(claim.comments);
                    _this.tempClaimComment = null;
                }
            }, function (error) {
                console.log("error");
                console.log(error);
            });
        }
    };
    HomeComponent.prototype.getClaimDescription = function (userComment) {
        var description = ";";
        if (userComment && userComment.description) {
            if (userComment.callType != null)
                description = description + "Call Type: " + userComment.callType + ", ";
            if (userComment.description != null)
                description = description + "Desc: " + userComment.description + ", ";
            if (userComment.assignedTo)
                description = description + "Assigned to: " + userComment.assignedTo + ", ";
            if (userComment.status != null)
                description = description + "Status: " + userComment.status + ", ";
            if (userComment.priority != null)
                description = description + "Priority: " + userComment.priority + ", ";
        }
        return description;
    };
    // save exception text
    HomeComponent.prototype.saveException = function () {
        var _this = this;
        //this.tempExceptionalCommnet.imei = this.getImeiNumber();
        this.tempExceptionalCommnet.userName = this.username;
        if ((this.tempExceptionalCommnet.imeiphonenumber == null) || (this.tempExceptionalCommnet.callType == null) || (this.tempExceptionalCommnet.description == null)) {
            alert('Please enter all mandatory fields..!!');
            return;
        }
        if (this.tempExceptionalCommnet.description != null && this.tempExceptionalCommnet.description.length == 0 && this.tempExceptionalCommnet.imeiphonenumber.length < 10) {
            alert('Please enter all mandatory fields..!!');
            return;
        }
        if (this.tempExceptionalCommnet.status == null) {
            this.tempExceptionalCommnet.status = "Open";
        }
        if (this.tempExceptionalCommnet.priority == null) {
            this.tempExceptionalCommnet.priority = "Low";
        }
        this.spinner.show();
        setTimeout(function () {
            /** spinner ends after 5 seconds */
            _this.spinner.hide();
        }, 3000);
        console.log('--->');
        var d = new Date();
        console.log(d);
        var s = this.datePipe.transform(d, "MM-dd-yyyy HH:mm");
        this.searchSubscription = this.saveService.saveCommentFromUser(this.tempExceptionalCommnet)
            .subscribe(function (response) {
            _this.clearForm();
            _this.tempExceptionalCommnet = null;
            alert("Data saved");
        }, function (error) {
        });
        // this.searchSubscription = this.saveService.saveComment(this.commentsFormdescription, this.commentsmobileimei, this.commentsFormcallType, this.commentsFormassignTo, this.commentsFormstatus, this.commentsFormpriority, "Exception", s)
        //   //description: string,related_to:String,callType:String,assignedTo:String,status:String,priority:String
        //   .subscribe(
        //     response => {
        //       console.log("Comments Saved..!!");
        //       //console.log(response);
        //       this.saveResponse = response;  
        //       this.clearForm();
        //       alert('Data Saved.');         
        //     },
        //     error => {
        //       console.log("error");
        //     }
        //   )
    };
    // save comment text
    HomeComponent.prototype.saveComment = function (related_to) {
        var _this = this;
        if (related_to) {
            this.commentArea = false;
            if ((this.commentsFormcallType == null) || (this.commentsFormdescription == null)) {
                alert('Please enter all mandatory fields..!!');
                return;
            }
            if (this.commentsFormdescription != null && this.commentsFormdescription.length == 0) {
                alert('Please enter all mandatory fields..!!');
                return;
            }
            if (this.commentsFormstatus == null) {
                this.commentsFormstatus = "Open";
            }
            if (this.commentsFormpriority == null) {
                this.commentsFormpriority = "Low";
            }
            this.spinner.show();
            setTimeout(function () {
                /** spinner ends after 5 seconds */
                _this.spinner.hide();
            }, 3000);
            console.log('--->');
            var d = new Date();
            console.log(d);
            var s_1 = this.datePipe.transform(d, "MM-dd-yyyy HH:mm");
            if (this.commentsFormassignTo != null) {
                this.commentsFormassignTo = "Assigned To : " + this.commentsFormassignTo;
            }
            this.searchSubscription = this.saveService.saveComment("Description : " + this.commentsFormdescription, related_to, "Call Type : " + this.commentsFormcallType, this.commentsFormassignTo, "Status : " + this.commentsFormstatus, "Priority : " + this.commentsFormpriority, "Claim", s_1)
                //description: string,related_to:String,callType:String,assignedTo:String,status:String,priority:String
                .subscribe(function (response) {
                console.log("Comments Saved..!!");
                //console.log(response);
                _this.saveResponse = response;
                for (var _i = 0, _a = _this.searchResult.claims; _i < _a.length; _i++) {
                    var claim = _a[_i];
                    if (claim.id == related_to) {
                        var com = new Comments();
                        com.commentcontent = _this.claimComment;
                        console.log(s_1);
                        com.createdtime = s_1;
                        com.callType = _this.commentsFormcallType;
                        com.description = _this.commentsFormdescription;
                        com.priority = _this.commentsFormpriority;
                        com.status = _this.commentsFormstatus;
                        com.assignedTo = _this.commentsFormassignTo;
                        com.commentcontent = com.description + com.callType + com.status + com.priority;
                        claim.comments.push(com);
                    }
                    _this.clearForm();
                }
            }, function (error) {
                console.log("error");
            });
        }
    };
    HomeComponent.prototype.clearForm = function () {
        this.commentsFormcallType = null;
        this.commentsFormdescription = null;
        this.commentsFormassignTo = null;
        this.commentsmobileimei = null;
        this.commentsFormpriority = "Low";
        this.commentsFormstatus = "Open";
    };
    HomeComponent.prototype.showCommentArea = function () {
        console.log('show commment area ');
        this.commentArea = true;
        this.claimComment = '';
    };
    // get chat messages 
    HomeComponent.prototype.getChat = function (episodeIds) {
        var _this = this;
        var _loop_1 = function (episodeId) {
            this_1.conversationSubscriptions = this_1.conversationService.getChatMessages(episodeId)
                .subscribe(function (response) {
                console.log("chat messages");
                console.log(response);
                if (_this.searchResult.activity == null) {
                    _this.searchResult.activity = new Activity();
                }
                _this.searchResult.activity.episodeChats.push(new EpisodeChat(episodeId, response));
                console.log(_this.searchResult.activity);
            }, function (error) {
            });
        };
        var this_1 = this;
        for (var _i = 0, episodeIds_1 = episodeIds; _i < episodeIds_1.length; _i++) {
            var episodeId = episodeIds_1[_i];
            _loop_1(episodeId);
        }
    };
    HomeComponent.prototype.onPolicyClick = function (policyIndex, policy) {
        console.log("onPolicyClick");
        console.log(policyIndex);
        this.policyIndex = policyIndex;
        this.onclickpolicytab(policy);
        this.selectedClientName = this.searchResult.policies[policyIndex].clientName;
        this.selectedPolicyNo = this.searchResult.policies[policyIndex].policyId;
    };
    HomeComponent.prototype.getStringDateFromMillis = function (millis) {
        return moment(new Date(millis)).format("HH:mm");
    };
    HomeComponent.prototype.isPolicyStatusOtherThanCurrent = function (policyStatus) {
        if (policyStatus == null || policyStatus.trim().length == 0) {
            return false;
        }
        if (policyStatus.trim().toUpperCase() != "CURRENT") {
            return true;
        }
        return false;
    };
    HomeComponent.prototype.isDateLessThanToday = function (dateToBecompare) {
        if (dateToBecompare != null && dateToBecompare != 0) {
            var dateObjToBecompare = new Date(dateToBecompare);
            var todayDate = new Date();
            if (dateObjToBecompare <= todayDate) {
                return true;
            }
            return false;
        }
    };
    HomeComponent.prototype.isDateGreaterThanToday = function (dateToBecompare) {
        if (dateToBecompare != null && dateToBecompare != 0) {
            var dateObjToBecompare = new Date(dateToBecompare);
            var todayDate = new Date();
            if (dateObjToBecompare > todayDate) {
                return true;
            }
            return false;
        }
    };
    HomeComponent.prototype.startTimer = function () {
        var _this = this;
        this.interval = setInterval(function () {
            if (_this.timeLeft < 60) {
                _this.timeLeft++;
                if (_this.timeLeft < 10) {
                    _this.secondadd = "0";
                }
                else {
                    _this.secondadd = "";
                }
            }
            else {
                _this.minutes++;
                _this.timeLeft = 0;
                _this.secondadd = "0";
                if (_this.minutes < 10) {
                    _this.minuteadd = "0";
                }
                else {
                    _this.minuteadd = "";
                }
            }
        }, 1000);
    };
    HomeComponent.prototype.clearTimer = function () {
        clearInterval(this.interval);
        this.minutes = 0;
        this.timeLeft = 0;
        this.secondadd = "0";
        this.minuteadd = "0";
    };
    HomeComponent.prototype.lessThanThirtyDays = function (dateToBecompare) {
        if (dateToBecompare != null && dateToBecompare != 0) {
            var dateObjToBecompareObj = new Date(dateToBecompare);
            dateObjToBecompareObj.setDate(dateObjToBecompareObj.getDate() - 30);
            var todayDate = new Date();
            if (dateObjToBecompareObj <= todayDate) {
                return true;
            }
            return false;
        }
    };
    HomeComponent.prototype.newChat = function () {
        this.router.navigate(['/pg/home']);
        if (this.interval != undefined) {
            this.clearTimer();
        }
        this.selectedClientName = '';
        this.selectedPolicyNo = '';
        this.searchResult = new SearchResponse;
        this.startTimer();
        var event = new EventData();
        event.eventId = "CONVERSATION_RESTART";
        event.messageText = "start";
        event.displayText = "start";
        event.formResult = {};
        this.textToBeSearch = null;
        this.searchResult = new SearchResponse();
        this.searchFl = false;
        if (this.textToBeSearch) {
            event.contextData = { imei: null };
        }
        this.raiseEventToIframe(event);
    };
    HomeComponent.prototype.refreshChat = function () {
        console.log("refresh chat");
        if (this.interval != undefined) {
            this.clearTimer();
        }
        this.startTimer();
        var event = new EventData();
        event.eventId = "CONVERSATION_RESTART";
        event.messageText = "start";
        event.displayText = "start";
        event.formResult = {};
        if (this.textToBeSearch) {
            event.contextData = { imei: this.textToBeSearch };
        }
        this.raiseEventToIframe(event);
    };
    HomeComponent.prototype.raiseEventToIframe = function (eventData) {
        this.autoBotRefId.nativeElement.contentWindow.postMessage(eventData, "*");
    };
    HomeComponent.prototype.exceptionLog = function () {
        console.log('inside exception log');
        this.isException = true;
        this.loadPage = false;
    };
    HomeComponent.prototype.open = function (content) {
        var _this = this;
        console.log('inside open modal');
        this.tempExceptionalCommnet = new Comments();
        this.tempExceptionalCommnet.type = "Exception";
        this.tempExceptionalCommnet.imei = this.getImeiNumber();
        this.modalService.open(content, { size: 'lg' }).result.then(function (result) {
            _this.closeResult = "Closed with: " + result;
        }, function (reason) {
            _this.closeResult = "Dismissed " + _this.getDismissReason(reason);
        });
    };
    HomeComponent.prototype.getDismissReason = function (reason) {
        console.log('inside dismiss');
        if (reason === ModalDismissReasons.ESC) {
            console.log('esc');
            return 'by pressing ESC';
        }
        else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            console.log('click');
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    HomeComponent.prototype.getCustomerName = function (customer) {
        var name = "";
        if (customer.firstName) {
            name = customer.firstName + " ";
        }
        if (customer.lastName) {
            name = name + customer.lastName;
        }
        return name;
    };
    HomeComponent.prototype.getBalanceConver = function (pollicy) {
        var totalBenefit = 0;
        if (typeof pollicy.totalBenefit == "string" && pollicy.totalBenefit != null) {
            totalBenefit = parseFloat(pollicy.totalBenefit);
        }
        var repairCost = 0;
        if (pollicy.repairCost != null && typeof pollicy.repairCost == "string") {
            repairCost = parseFloat(pollicy.repairCost);
        }
        if (totalBenefit > 0) {
            return (totalBenefit - repairCost) / (totalBenefit * 1.0);
        }
        return 0;
    };
    HomeComponent.prototype.getcustomerMobileNumber = function () {
        if (this.searchResult && this.searchResult.customerInfo && this.searchResult.customerInfo.phoneNumber) {
            return this.searchResult.customerInfo.phoneNumber;
        }
        if (this.searchResult.policies) {
            for (var _i = 0, _a = this.searchResult.policies; _i < _a.length; _i++) {
                var pol = _a[_i];
                if (pol.mobileNo) {
                    return pol.mobileNo;
                }
            }
        }
        return null;
    };
    HomeComponent.prototype.clearCache = function () {
        this.clearDataFromCache = true;
        this.searchText();
    };
    return HomeComponent;
}());
export { HomeComponent };
