var CustomerInfo = /** @class */ (function () {
    function CustomerInfo() {
        this._id = null;
        this.alternateMobileNumber = null;
        this.assignedTo = null;
        this.billingCity = null;
        this.billingCountry = null;
        this.billingPostalCode = null;
        this.billingState = null;
        this.billingStreet = null;
        this.createdDt = 0;
        this.createdTime = 0;
        this.customerId = null;
        this.customerNumber = null;
        this.description = null;
        this.firstName = null;
        this.isPortalUser = null;
        this.lastModifiedBy = null;
        this.lastName = null;
        this.modifiedTime = 0;
        this.phoneNumber = null;
        this.portalPassowrd = null;
    }
    return CustomerInfo;
}());
export { CustomerInfo };
