import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject, from } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { SearchResponse } from '../../app/models/searchresponse.model'
import { Policy } from '../models/policy.model';
import { Claim } from '../models/claim.model';
import { Comments } from '../models/comments.model'
import { CustomerInfo } from '../models/customerInfo.model';
import { SearchRequest } from '../models/searchrequest.model';

@Injectable()
export class SearchService {

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(
        private httpClient: HttpClient
    ) { }

    search(searchText: string): Observable<SearchResponse> {
        const subject = new Subject<SearchResponse>();
        const url = `${environment.apiurl + environment.searchApi}`;
        const body = { "imei-number": searchText, "companyContext": { "companyId": environment.companyId } };
        this.httpClient.post<SearchResponse>(
            url,
            body,
            {
                headers: this.httpHeaders,
                observe: 'response'
            }
        ).subscribe(
            (response: HttpResponse<SearchResponse>) => {
                if (response && response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getPoliciesByImei(searchRequest: SearchRequest): Observable<Policy[]> {
        const subject = new Subject<Policy[]>();
        const url = `${environment.apiurl + environment.policies}`;
        searchRequest.companyContext = { "companyId" : environment.companyId };
        //const body = { "imei" : imei, "companyContext": { "companyId": environment.companyId } };
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<Policy[]>(
            url,
            searchRequest,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    if (response.body.success) {
                        subject.next(response.body.result);
                    }
                    
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getClaimsByImei(searchRequest: SearchRequest): Observable<Claim[]> {
        const subject = new Subject<Claim[]>();
        const url = `${environment.apiurl + environment.claims}`;
        searchRequest.companyContext = { "companyId" : environment.companyId };
        //const body = { "imei" : imei, "companyContext": { "companyId": environment.companyId } };
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<Claim[]>(
            url,
            searchRequest,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    if (response.body.success) {
                        subject.next(response.body.result);
                    }
                    
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getPolicyClaimDetails(policies: Policy[], claims: Claim[]): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.apiurl + environment.policyclaimdetails}`;
        const body = { "policies" : policies, "claims" : claims, "companyContext": { "companyId": environment.companyId } };
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    if (response.body.success) {
                        subject.next(response.body.result);
                    }
                    
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getCustomerByCustomerId(customerId: string): Observable<CustomerInfo> {
        const subject = new Subject<CustomerInfo>();
        const url = `${environment.apiurl + environment.customerbyid}`;
        const body = { "customerId" : customerId, "companyContext": { "companyId": environment.companyId } };
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<CustomerInfo>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    if (response.body.success) {
                        subject.next(response.body.result);
                    }
                    
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getUpdatedCustomerInfo(customerInfo: CustomerInfo, policies: Policy[]): Observable<CustomerInfo> {
        const subject = new Subject<CustomerInfo>();
        const url = `${environment.apiurl + environment.policyclaimdetails}`;
        const body = { "customerInfo" : customerInfo, "policies" : policies, "companyContext": { "companyId": environment.companyId }, "getUpdateCustomerInfo" : true };
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<CustomerInfo>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    if (response.body.success) {    
                        subject.next(response.body.result);
                    }
                    
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getComments(imei: string, userName: string, parentId: string, type: string): Observable<Comments[]> {
        const subject = new Subject<Comments[]>();
        const url = `${environment.apiurl + environment.comments}`;
        const body = {
                key: {
                    imei : imei, 
                    parentId: parentId,
                    type: type
                },
                operation: "GET_ALL",
                companyContext: { companyId: environment.companyId } 

            };
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<Comments[]>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    if (response.body.success) {
                        subject.next(response.body.result);
                    }
                    
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getClaimCommentsFromSecondCRM(claimId: string): Observable<Comments[]> {
        const subject = new Subject<Comments[]>();
        const url = `${environment.apiurl + environment.comments}`;
        const body = {
                claimId: claimId,
                operation: "GET_CLAIM_COMMNETS",
                companyContext: { companyId: environment.companyId } 

            };
        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + environment.bearertoken
        });
        this.httpClient.post<Comments[]>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    if (response.body.success) {
                        console.log("claim comments service");
                        console.log(response.body.result);
                        subject.next(response.body.result);
                    }
                    
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }

    getEcertByImei(imei: string,smsFlag:boolean,policyId:string): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.awsurl + environment.policiesForEcertApi}`;
        const body = {
                companyContext: { companyId: environment.ecertCompanyId },
                imei: imei,
                noSendEcert: smsFlag,
                policyId:policyId
            };
            const httpHeaders = new HttpHeaders({
            'X-Api-Key':environment.x_Api_Key
        });
        this.httpClient.post<any>(
            url,
            body,
            {
                headers: httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response && response.body) {
                    if (response.body.success) {
                        console.log("claim ecert download ");
                        subject.next(response.body.result);
                    }
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }
}