var Comments = /** @class */ (function () {
    function Comments() {
        this.imei = null;
        this.assigned_user_id = null;
        this.createdtime = null;
        this.commentcontent = null;
        this.parentId = null;
        this.callType = null;
        this.description = null;
        this.assignedTo = null;
        this.status = null;
        this.priority = null;
        this.createdDt = null;
        this.type = null;
        this.userName = null;
        this.imeiphonenumber = null;
    }
    return Comments;
}());
export { Comments };
//{"parentId":"15x11964848","callType":"TODO","description":"POLICY DESCRIPTION 12344","assignedTo":"SHIVAM","status":"ASSIGNED STATUS","priority":"MEDIUM","createdDt":"2017-02-17"}
