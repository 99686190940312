var SearchRequest = /** @class */ (function () {
    function SearchRequest() {
        this.policyId = [];
        this.imei = null;
        this.phoneNumber = null;
        this.companyContext = {};
        this.clearCache = false;
        this.userName = null;
    }
    return SearchRequest;
}());
export { SearchRequest };
