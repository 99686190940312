export class Comments {
    imei: string = null;
    assigned_user_id: string=null;
    createdtime: string = null;
    commentcontent: string = null;
    parentId: string = null;
    callType: string = null;
    description: string =null;
    assignedTo : string =null;
    status : string =null;
    priority: string=null;
    createdDt: string=null;
    type: string = null;
    userName: string = null;
    imeiphonenumber: string = null;
}


//{"parentId":"15x11964848","callType":"TODO","description":"POLICY DESCRIPTION 12344","assignedTo":"SHIVAM","status":"ASSIGNED STATUS","priority":"MEDIUM","createdDt":"2017-02-17"}