
<div class="m-grid m-grid--hor m-grid--root m-page">
    
			
        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2" id="m_login">
    <div class="m-grid__item m-grid__item--fluid	m-login__wrapper">
    <div class="m-login__container">
      <div class="m-login__logo">
        <img src="assets/images/AMS-Logo.png" class="img-fluid">	
        
      </div>
      <div class="m-login__signin">
        <div class="m-login__head">
          <h2 class="m-login__title font-weight-bold text-dark">Log In to Bolttech Device Protection India Private Limited</h2>
        </div>
        <form class="m-login__form m-form" action="">
                <div *ngIf="errorMessage!=null" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>
          <div class="form-group m-form__group">
                <label class="control-label" for="signupEmail">Email</label>
                <input id="signupEmail" required type="email" maxlength="100" class="form-control" [(ngModel)]="email"
                       [ngModelOptions]="{standalone: true}">
          </div>
          <div class="form-group m-form__group">
                <label class="control-label" for="signupPassword">Password</label>
                <input id="signupPassword" required type="password" maxlength="25" class="form-control"
                       length="40" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="row m-login__form-sub">
            <div class="col m--align-left m-login__form-left">
              <label class="m-checkbox  m-checkbox--focus">
              <input type="checkbox" name="remember"> Remember me
              <span></span>
              </label>
            </div>
            
          </div>
          <div class="m-login__form-action">
            <button (click)="onLogin()" id="m_login_signin_submit" class="btn btn-success m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--success">Log In</button>
          </div>
        </form>
      </div>
      
    </div>	
    </div>
    </div>				
    
    
    </div>
    
    

